import MapboxGL from 'mapbox-gl';
import MapboxSDK from '@mapbox/mapbox-sdk';
import MapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { APIConfig } from 'src/config';
import { getLocale, getLanguage } from 'src/util/i18n/handler';

MapboxGL.accessToken = APIConfig()?.MAPBOX_TOKEN;

const MapContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

// Map controls are hidden via global stylesheet on the mapbox component
class Map extends React.Component {
  componentDidMount() {
    if (!MapboxGL.accessToken) { return; }

    this.map = new MapboxGL.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [0, 0],
      zoom: 15,
    });

    this.focus();
  }

  componentWillUnmount() {
    if (this.map) { this.map.remove(); }
  }

  focus = () => {
    const { address, zoom } = this.props;
    const { gps } = address;

    if (gps) {
      const { latitude, longitude } = gps;

      this.map.setZoom(zoom);
      this.map.setCenter([longitude, latitude]);

      // eslint-disable-next-line react/no-unused-class-component-methods -- added to map.
      this.marker = new MapboxGL.Marker({ color: '#3bb667' })
        .setLngLat([longitude, latitude])
        .addTo(this.map);

      return;
    }

    this.map.addControl(new MapboxLanguage({
      defaultLanguage: getLanguage(getLocale()),
    }));
    const {
      line1, line2, city, state, postcode, country,
    } = address;

    const query = [
      [line1, line2].join(' '),
      city,
      state,
      postcode,
      country,
    ].join(',');

    const mbxClient = MapboxSDK({ accessToken: MapboxGL.accessToken });
    const geocodingClient = MapboxGeocoding(mbxClient);

    geocodingClient.forwardGeocode({ query, limit: 1 })
      .send()
      .then((response) => {
        if (response && response.body && response.body.features && response.body.features.length) {
          const feature = response.body.features[0];

          this.map.setZoom(zoom);
          this.map.setCenter(feature.center);

          // eslint-disable-next-line react/no-unused-class-component-methods -- added to map.
          this.marker = new MapboxGL.Marker({ color: '#3bb667' })
            .setLngLat(feature.center)
            .addTo(this.map);
        }
      });
  };

  render() {
    if (!MapboxGL.accessToken) { return null; }

    const { width, height } = this.props;

    return (
      <MapContainer
        ref={(el) => { this.mapContainer = el; }}
        width={width}
        height={height}
      // onStyleLoad={changeMapLanguage}
      />
    );
  }
}

Map.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    gps: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }),
  }),
  zoom: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
};

Map.defaultProps = {
  address: null,
  height: '200px',
  width: '100%',
  zoom: 12,
};

export default Map;
