import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  Card, CardFooter, CardHeader,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import PropertyTitle from './PropertyTitle';
import PropertyMetadata from './PropertyMetadata';

const Metadata = styled.div.attrs({
  className: 'float-end',
})``;

const PropertyDetails = styled.h3`
  font-size: 1rem;
  margin: 0;
`;

const MapContainer = styled.div.attrs({
  className: 'card-img-top',
})``;

const Footer = styled(CardFooter).attrs({
  className: 'text-center',
})``;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - PropertyCard component
 */
function PropertyCard(props) {
  const {
    alias,
    address,
    path,
    consumer,
    solar,
    wind,
    map,
  } = props;

  return (
    <Card>
      <CardHeader>
        <Metadata>
          <PropertyMetadata consumer={consumer} solar={solar} wind={wind} />
        </Metadata>
        <PropertyDetails>
          <PropertyTitle title={alias} address={address} />
        </PropertyDetails>
      </CardHeader>
      <MapContainer>
        {map}
      </MapContainer>
      <Footer>
        <Link to={path} className="btn btn-primary"><FormattedMessage id="property.property_card.manage_property" defaultMessage="Manage Property" /></Link>
      </Footer>
    </Card>
  );
}

PropertyCard.propTypes = {
  alias: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  path: PropTypes.string,
  consumer: PropTypes.bool,
  solar: PropTypes.bool,
  wind: PropTypes.bool,
  map: PropTypes.element,
};

PropertyCard.defaultProps = {
  path: null,
  consumer: false,
  solar: false,
  wind: false,
  map: null,
};

export default PropertyCard;
