import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Col, Row, Button,
  Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter,
} from 'reactstrap';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

import { APIConfig } from 'src/config';
import Loading from 'src/components/Loading';
import { buyPriceInformation, sellPriceInformation } from 'src/components/TradeRule/TradeRuleHelpers';
import { RESIDUAL } from 'src/util/constants';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import { emptyTradeRulesClassName } from 'src/helpers/tradeHelpers';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';

class TradeRuleActiveResidual extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.residualRules) {
        meterEdge.node.residualRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== 'TRADE_RULE_STATE_ACCEPTED'
            || ruleNode.tradeType !== 'TRADE_TYPE_RESIDUAL'
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    return rules;
  }

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property } = this.props;
    const { formatMessage } = intl;
    const tradeRules = TradeRuleActiveResidual.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, RESIDUAL);
    const singleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_residual.trade.singular', defaultMessage: 'retailer default trade rule' });
    const multipleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_residual.trade.multiple', defaultMessage: 'retailer default trade rules' });

    return (
      <Card className={`mt-4 mb-4 trade-rules-residual${noTradeRulesClass}`}>
        <CardBody>
          <h2><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.title" defaultMessage="Retailer Default Trades" /></h2>
          <p>
            <FormattedMessage
              id="trade_rule.trade_rule_active.trade_rule_active_residual.help_text"
              defaultMessage="The retailer default trades are at the agreed energy tariffs that you have contracted with your retailer."
            />
          </p>
          <Row>
            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.title" defaultMessage="Selling" /></h3>
              <p>
                <FormattedMessage
                  id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.count_details"
                  defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to sell energy."
                  values={{
                    tradeRulesCount: <strong>{tradeRules.sell.length}</strong>,
                    tradeRulesCountLabel: <FormattedPlural
                      value={tradeRules.sell.length}
                      one={singleTrade}
                      other={multipleTrade}
                    />,
                  }}
                />
              </p>
              {tradeRules.sell && tradeRules.sell.map((rule) => {
                const { minimum, maximum } = tradeRulePriceRange(rule) || {};
                return (
                  <Card className="mb-4 mt-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.details"
                          defaultMessage="Selling from {meterTitle}"
                          values={{
                            meterTitle: rule.meter.title,
                          }}
                        />
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        {
                          sellPriceInformation({
                            tradeType: rule.tradeType,
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_residual.sell.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                        <br />
                        {rule.finish && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.start_and_finish.text"
                            defaultMessage="From {startDate} to {finishDate}"
                            values={{
                              startDate: getStringAndNumericFormattedDate(intl, rule.start),
                              finishDate: getStringAndNumericFormattedDate(intl, rule.finish),
                            }}
                          />
                        )}
                        {!rule.finish && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.start_only.text"
                            defaultMessage="From {startDate} onwards"
                            values={{
                              startDate: getStringAndNumericFormattedDate(intl, rule.start),
                            }}
                          />
                        )}

                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.title" defaultMessage="Buying" /></h3>
              <p>
                <FormattedMessage
                  id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.count_details"
                  defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to buy energy."
                  values={{
                    tradeRulesCount: <strong>{tradeRules.buy.length}</strong>,
                    tradeRulesCountLabel: <FormattedPlural
                      value={tradeRules.buy.length}
                      one={singleTrade}
                      other={multipleTrade}
                    />,
                  }}
                />
              </p>

              {tradeRules.buy && tradeRules.buy.map((rule) => {
                const { minimum, maximum } = tradeRulePriceRange(rule) || {};
                return (
                  <Card className="mb-4 mt-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.details"
                          defaultMessage="Buying for {meterTitle}"
                          values={{
                            meterTitle: rule.meter.title,
                          }}
                        />
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        {
                          buyPriceInformation({
                            tradeType: rule.tradeType,
                            mode: APIConfig().MODE,
                            rule,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_residual.buy.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                        <br />
                        {rule.finish && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.start_and_finish.text"
                            defaultMessage="From {startDate} to {finishDate}"
                            values={{
                              startDate: getStringAndNumericFormattedDate(intl, rule.start),
                              finishDate: getStringAndNumericFormattedDate(intl, rule.finish),
                            }}
                          />
                        )}
                        {!rule.finish && (
                          <FormattedMessage
                            id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.start_only.text"
                            defaultMessage="From {startDate} onwards"
                            values={{
                              startDate: getStringAndNumericFormattedDate(intl, rule.start),
                            }}
                          />
                        )}
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Link to={`/properties/${property.id}/settings#tariffs`}>
            <Button color="primary" className="me-2">
              <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.view_agreed_tariffs.label" defaultMessage="View agreed energy tariffs" />
            </Button>
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveResidual.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveResidual.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleActiveResidual,
  {
    property: graphql`
      fragment TradeRuleActiveResidual_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              residualRules: rules(first: 500, type: TRADE_TYPE_RESIDUAL, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
