import { DateTime } from 'luxon';
import { APIConfig } from 'src/config';

/**
 * Transform a date implemented as a DateTime in local time, to
 * a timestamp in DateTime in the given preferred timezone.
 * @param {(DateTime|null)} date
 * @param {string} tz name of timezone
 * @returns {(DateTime|null)} the date in the timezone.
 */
export const dateToTimeInTimezone = (date, tz) => {
  if (!date) { return null; }

  const timezone = tz || APIConfig().DEFAULT_TIMEZONE;

  return DateTime.fromObject(
    { year: date.year, month: date.month, day: date.day },
    { zone: timezone },
  );
};

/**
 * For a given date range a finish date is represented as midnight of the day in our date range
 * object, however as a time range it is considered at 24:00:00 that is midnight the following day.
 * A day is a 24 hours interval (not withstanding daylight saving and/or leap seconds). Hence the
 * addition of one day.
 * @param {object} dateRange
 * @param {DateTime} dateRange.start
 * @param {DateTime} dateRange.finish
 * @param {string} tz
 * @returns {object} - time range.
 */
export const dateRangeToTimeRange = (dateRange, tz) => {
  if (!dateRange) return null;

  const timezone = tz || APIConfig().DEFAULT_TIMEZONE;
  const { start: startDate, finish: finishDate } = dateRange;

  const start = dateToTimeInTimezone(startDate, timezone);
  const finish = dateToTimeInTimezone(finishDate, timezone)?.plus({ days: 1 });

  return { start, finish };
};
