import React from 'react';
import { useIntl } from 'react-intl';

/**
 * Description
 * @returns {React.ReactComponentElement} - Loading component
 */
function Loading() {
  const intl = useIntl();
  const loadingLabel = intl.formatMessage({ id: 'loading.application.loading.label', defaultMessage: 'Loading...' });
  return (
    <div
      className="container center text-center"
      style={{
        marginTop: 40,
        paddingTop: 30,
      }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">{loadingLabel}</span>
      </div>
    </div>
  );
}

export default Loading;
