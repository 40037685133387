/* eslint-disable jsx-a11y/control-has-associated-label */
import { format } from 'd3-format';
import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessage, injectIntl } from 'react-intl';

import BadgeTradeDirection from 'src/components/Badge/BadgeTradeDirection';
import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Time from 'src/enosikit/components/Time';

import { APIConfig } from 'src/config';
import { getPropertyLink } from 'src/helpers/tradeHelpers';
import { PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import username from 'src/util/decorators/username';
import { getLocale, i18nDecimalFormat } from 'src/util/i18n/handler';

/**
 * returns the meter label
 * @param {string} identifier - meter identifier
 * @param {string} title - meter title
 * @returns {string} - meter label
 */
export const getMeterLabel = (identifier, title) => {
  if (!identifier && !title) {
    return null;
  }
  let meterDetails = title || '';
  meterDetails = identifier ? `${title} (${identifier})` : meterDetails;
  return meterDetails?.trim();
};

/**
 * returns the Trader/Trade Point React element
 * @param {object} trader - user data object
 * @returns {React.ReactElement} - trader details (meter, property etc)
 */
export const getTrader = (trader) => {
  if (!trader || trader === undefined) {
    return null;
  }
  if (!trader.tradePoint || trader.tradePoint === undefined) {
    return null;
  }
  const {
    communityId, community, tradePoint, userId, user,
  } = trader;
  const { id, type, meter } = tradePoint || {};
  const { identifier, title } = meter || {};
  const meterLabel = getMeterLabel(identifier, title);
  const propertyLink = getPropertyLink(tradePoint);
  return (
    <dl>
      {user && (
        <>
          <dt><FormattedMessage id="trade.trade_list.trade_list_table.user.trader.label" defaultMessage="Trader" /></dt>
          <dd>
            {username(user)}
            <br />
            <UUID uuid={userId} />
          </dd>
        </>
      )}
      {community && (
        <>
          <dt><FormattedMessage id="trade.trade_list.trade_list_table.community.trader.label" defaultMessage="Trader" /></dt>
          <dd>
            {community.title}
            <br />
            <UUID uuid={communityId} />
          </dd>
        </>
      )}
      <dt><FormattedMessage id="trade.trade_list.trade_list_table.trade_point.label" defaultMessage="Trade Point" /></dt>
      <dd>
        {meterLabel && (
          <>
            <span>{meterLabel}</span>
            <br />
          </>
        )}
        {propertyLink && (
          <>
            {propertyLink}
            <br />
          </>
        )}
        <UUID uuid={id} />
        <br />
        <BadgeTradePointType type={type} />
      </dd>
    </dl>
  );
};

const locale = getLocale();
class TradeListTable extends React.Component {
  static tradesForProperty(property) {
    const { meters: meterConn } = property;

    let allTrades = [];

    if (meterConn === undefined || meterConn.edges.length === 0) {
      return allTrades;
    }
    meterConn.edges.forEach((el) => {
      const { node: meter } = el;
      const {
        rules: ruleConn, trades: tradeConn, id: meterId,
        identifier: meterIdentifier, title: meterTitle,
      } = meter;
      let allRules = {};

      if (!tradeConn || tradeConn === undefined) {
        return;
      }
      if (ruleConn && ruleConn !== undefined) {
        allRules = ruleConn.edges.reduce((acc, edge) => {
          acc[edge.node.id] = edge.node;
          return acc;
        }, {});
      }

      allTrades = allTrades.concat(
        tradeConn.edges.map((edge) => {
          const { node: trade } = edge;
          return ({
            ...trade,
            meter: { id: meterId, identifier: meterIdentifier, title: meterTitle },
            rule: allRules[trade.ruleId],
          });
        }),
      );
    });

    return allTrades;
  }

  static tradeSummary(trade, intl) {
    if (!trade || trade === undefined) {
      return null;
    }
    // TO DO: Implement d3-format locale support (PT-1124)
    const formattedTradeVolume = i18nDecimalFormat(format('.4s')(trade.volume || 0));
    const tradeVolumeUnitTitle = intl.formatMessage({ id: 'trade.trade_list.trade_list_table.trade_volume.abbr.title', defaultMessage: 'watt hour' });
    const tradeVolumeUnitLabel = <FormattedMessage id="trade.trade_list.trade_list_table.trade_volume.abbr.label" defaultMessage="Wh" />;
    const tradeVolumeUnit = <abbr title={tradeVolumeUnitTitle}>{tradeVolumeUnitLabel}</abbr>;

    const energyCostUnitTitle = intl.formatMessage({ id: 'trade.trade_list.trade_list_table.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const energyCostUnitLabel = <FormattedMessage id="trade.trade_list.trade_list_table.energy_cost.abbr.label" defaultMessage="c/kWh" />;
    const energyCostUnit = <abbr title={energyCostUnitTitle}>{energyCostUnitLabel}</abbr>;

    const modeRebateDiscountLabel = APIConfig().MODE === PLATFORM_MODE_REBATE && <FormattedMessage id="trade.trade_list.trade_list_table.discount_of.label" defaultMessage="Discount of " />;

    // TO DO: Implement d3-format locale support (PT-1124)
    const formattedTradeValue = (
      <FormattedMessage
        id="trade.trade_list.trade_list_table.trade_value.label"
        defaultMessage="{n, number, :: .00 currency/AUD}"
        values={{ n: (trade?.value || 0) }}
      />
    );
    // TO DO: Implement d3-format locale support (PT-1124)
    const formattedConvertEnergyPrice = <FormattedMessage id="trade.trade_list.trade_list_table.trade_avg_price.label" defaultMessage="{n, number, :: .000}" values={{ n: (convertEnergyPrice(trade.price)) }} />;

    return (
      <>
        <FormattedMessage
          id="trade.trade_list.trade_list_table.trade_summary.trade_volume.text"
          defaultMessage="{tradeVolumeAndAbbrLabel} @ {convertEnergyPrice} {energyCostUnit}"
          values={{
            /* eslint-disable react/jsx-one-expression-per-line */
            tradeVolumeAndAbbrLabel: <strong>{formattedTradeVolume}{tradeVolumeUnit}</strong>,
            convertEnergyPrice: <strong>{formattedConvertEnergyPrice}</strong>,
            energyCostUnit,
          }}
        />
        <br />
        {APIConfig().MODE === PLATFORM_MODE_REBATE
          && (
            <FormattedMessage
              id="trade.trade_list.trade_list_table.trade_summary.trade_value.text"
              defaultMessage="{modeRebateDiscountLabel} {tradeValue}"
              values={{
                modeRebateDiscountLabel,
                tradeValue: <strong>{formattedTradeValue}</strong>,
              }}
            />
          )}
      </>
    );
  }

  filteredTradeForProperty = (property) => {
    const { filter } = this.props;
    const { direction, type } = filter;

    return TradeListTable.tradesForProperty(property).filter((trade) => {
      if (direction.length === 0 && type.length === 0) { return true; }
      const hasDirection = direction.length === 0 || direction.includes(trade.direction);
      const hasType = type.length === 0 || type.includes(trade.type);
      return hasDirection && hasType;
    });
  };

  setDate = (event, date) => {
    event.preventDefault();

    const { dateUpdateFunc } = this.props;

    dateUpdateFunc(date);
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { date, intl, property } = this.props;
    const { id: propertyId } = property;
    const trades = this.filteredTradeForProperty(property);

    const after = date.plus({ days: 1 });
    const before = date.plus({ days: -1 });

    return (
      <div className="mt-4 mb-4 card trades-history-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.id.label" defaultMessage="ID" /></th>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.timestamp.label" defaultMessage="Timestamp" /></th>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.details.label" defaultMessage="Details" /></th>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.direction.label" defaultMessage="Direction" /></th>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.type.label" defaultMessage="Type" /></th>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.tradepoint.label" defaultMessage="Trade Point" /></th>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.counterparty.label" defaultMessage="Counterparty" /></th>
                <th scope="col"><FormattedMessage id="trade.trade_list.trade_list_table.head.rule.label" defaultMessage="Rule" /></th>
              </tr>
            </thead>
            <tbody>
              {trades
                && trades.map((trade) => {
                  const {
                    id, direction, interval, meter, type, ruleId, rule,
                  } = trade || {};
                  const { buyer, seller } = rule || {};
                  const trader = direction === TRADE_DIRECTION_BUY ? buyer : seller;
                  const { tradePoint } = trader;
                  const propertyLink = getPropertyLink(tradePoint);
                  const intervalLength = `(${interval.length / 60}${intl.formatMessage({ id: 'minute_short', defaultMessage: 'min' })})`;
                  return (
                    <tr key={`trade-${id}`}>
                      <th scope="row" colSpan={2}>
                        <UUID uuid={id} />
                        <div data-testid="timestamp" style={{ whiteSpace: 'nowrap', fontWeight: 'normal' }}>
                          <FormattedMessage
                            id="trade.trade_list.trade_list_table.cell.timestamp.date.text"
                            defaultMessage="{formattedTimeDate}"
                            values={{
                              formattedTimeDate: <Time
                                child={DateTime.fromSeconds(
                                  interval.timestamp,
                                ).setLocale(locale).toFormat('HH:mm DD')}
                                unixTimestamp={interval.timestamp}
                              />,
                            }}
                          />
                          <br />
                          <FormattedMessage
                            id="trade.trade_list.trade_list_table.cell.timestamp.interval.content"
                            defaultMessage="{formattedInterval}"
                            values={{
                              formattedInterval: <small>{intervalLength}</small>,
                            }}
                          />
                        </div>
                      </th>
                      <td data-testid="trade-summary" style={{ whiteSpace: 'nowrap' }}>
                        {TradeListTable.tradeSummary(trade, intl)}
                      </td>
                      <td colSpan={2}>
                        <BadgeTradeDirection direction={direction} />
                        <br />
                        <BadgeTradeType type={type} />
                      </td>
                      <td>
                        <strong>
                          {meter.title}
                        </strong>
                        <br />
                        {meter.identifier}
                        {propertyLink && <br />}
                        {propertyLink}
                      </td>
                      <td>
                        {direction === TRADE_DIRECTION_BUY
                          ? getTrader(seller)
                          : getTrader(buyer)}
                      </td>
                      <td>
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>
                          <UUID uuid={ruleId} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot className="border-top-0">
              <tr>
                <th scope="row" colSpan={2}><FormattedMessage id="trade.trade_list.trade_list_table.foot.total_buy.label" defaultMessage="Total Buy" /></th>
                <td colSpan={6}>
                  {trades && trades.reduce((acc, trade) => {
                    if (trade.direction !== TRADE_DIRECTION_BUY) {
                      return acc;
                    }
                    acc[0].value += trade.value;
                    acc[0].volume += trade.volume;
                    return acc;
                  }, [{ value: 0, volume: 0 }]).map((summary) => {
                    const summaryVolumeUnitTitle = intl.formatMessage({ id: 'trade.trade_list.trade_list_table.foot.total_buy.label.trade_volume.abbr.title', defaultMessage: 'watt hour' });
                    const summaryVolumeUnitLabel = <FormattedMessage id="trade.trade_list.trade_list_table.foot.total_buy.label.trade_volume.abbr.label" defaultMessage="Wh" />;

                    const energyPriceUnitTitle = intl.formatMessage({ id: 'trade.trade_list.trade_list_table.foot.total_buy.label.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
                    const energyPriceUnitLabel = <FormattedMessage id="trade.trade_list.trade_list_table.foot.total_buy.label.abbr.label" defaultMessage="c/kWh" />;
                    return (
                      <div data-testid="total-buy" key="total-buy">
                        <strong>
                          <FormattedMessage
                            id="trade.trade_list.trade_list_table.foot.total_buy.trade_volume.text"
                            defaultMessage="{summaryVolume}{summaryVolumeUnit}"
                            values={{
                              // TO DO: Implement d3-format locale support (PT-1124)
                              summaryVolume: i18nDecimalFormat(format('.4s')(summary.volume || 0)),
                              // eslint-disable-next-line max-len
                              summaryVolumeUnit: <abbr title={summaryVolumeUnitTitle}>{summaryVolumeUnitLabel}</abbr>,
                            }}
                          />
                        </strong>
                        <br />
                        <strong>
                          <FormattedMessage
                            id="trade.trade_list.trade_list_table.buy.trade_summary_value.label"
                            defaultMessage="{n, number, :: .00 currency/AUD}"
                            values={{ n: (summary?.value || 0) }}
                          />
                        </strong>
                        {!!summary.volume && summary.volume > 0 && (
                          <>
                            <br />
                            <FormattedMessage
                              id="trade.trade_list.trade_list_table.foot.total_buy.energy_price.text"
                              defaultMessage="{energyPrice} {energyPriceUnit}"
                              values={{

                                energyPrice:
                                  // eslint-disable-next-line react/jsx-indent
                                  <strong>
                                    <FormattedMessage
                                      id="trade.trade_list.trade_list_table.foot.total_buy.avg_energy_price.label"
                                      defaultMessage="{n, number, :: .00}"
                                      values={{
                                        n:
                                          convertEnergyPrice(summary.value / summary.volume),
                                      }}
                                    />
                                  </strong>,
                                // eslint-disable-next-line max-len
                                energyPriceUnit: <abbr title={energyPriceUnitTitle}>{energyPriceUnitLabel}</abbr>,
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <th className="border-bottom-0" scope="row" colSpan={2}><FormattedMessage id="trade.trade_list.trade_list_table.foot.total_sell_label" defaultMessage="Total Sell" /></th>
                <td className="border-bottom-0" colSpan={6}>
                  {trades && trades.reduce((acc, trade) => {
                    if (trade.direction !== TRADE_DIRECTION_SELL) {
                      return acc;
                    }
                    acc[0].value += trade.value;
                    acc[0].volume += trade.volume;
                    return acc;
                  }, [{ value: 0, volume: 0 }]).map((summary) => {
                    const summaryVolumeUnitTitle = intl.formatMessage({ id: 'trade.trade_list.trade_list_table.foot.total_sells.label.trade_volume.abbr.title', defaultMessage: 'watt hour' });
                    const summaryVolumeUnitLabel = <FormattedMessage id="trade.trade_list.trade_list_table.foot.total_sells.label.trade_volume.abbr.label" defaultMessage="Wh" />;

                    const energyPriceUnitTitle = intl.formatMessage({ id: 'trade.trade_list.trade_list_table.foot.total_sell.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
                    const energyPriceUnitLabel = <FormattedMessage id="trade.trade_list.trade_list_table.foot.total_sell.energy_cost.abbr.label" defaultMessage="c/kWh" />;

                    return (
                      <div data-testid="total-sell" key="total-sell">
                        <strong>
                          <FormattedMessage
                            id="trade.trade_list.trade_list_table.foot.total_sell.trade_volume"
                            defaultMessage="{summaryVolume}{summaryVolumeUnit}"
                            values={{
                              // TO DO: Implement d3-format locale support (PT-1124)
                              summaryVolume: i18nDecimalFormat(format('.4s')(summary.volume || 0)),
                              // eslint-disable-next-line max-len
                              summaryVolumeUnit: <abbr title={summaryVolumeUnitTitle}>{summaryVolumeUnitLabel}</abbr>,
                            }}
                          />
                        </strong>
                        <br />
                        <strong>
                          <FormattedMessage
                            id="trade.trade_list.trade_list_table.sell.trade_summary_value.label"
                            defaultMessage="{n, number, :: .00 currency/AUD}"
                            values={{ n: (summary?.value || 0) }}
                          />
                        </strong>
                        {!!summary.volume && summary.volume > 0 && (
                          <>
                            <br />
                            <FormattedMessage
                              id="trade.trade_list.trade_list_table.foot.total_sell.energy_price"
                              defaultMessage="{energyPrice} {energyPriceAbbrLabel}"
                              values={{
                                energyPrice:
                                  // eslint-disable-next-line react/jsx-indent
                                  <strong>
                                    <FormattedMessage
                                      id="trade.trade_list.trade_list_table.foot.total_sell_price.label"
                                      defaultMessage="{n, number, :: .00 currency/AUD}"
                                      values={{
                                        n:
                                          convertEnergyPrice(summary.value / summary.volume),
                                      }}
                                    />
                                  </strong>,
                                // eslint-disable-next-line max-len
                                energyPriceAbbrLabel: <abbr title={energyPriceUnitTitle}>{energyPriceUnitLabel}</abbr>,
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="card-footer">
          <div className="trade-history-pagination">
            <nav aria-label="Trade history navigation">
              <ul className="pagination">
                <li className="page-item">
                  <Link
                    className="page-link trades-previous-day"
                    to={`/properties/${property.id}/trades/history/?date=${before.toISODate()}`}
                    onClick={(event) => (this.setDate(event, before))}
                  >
                    <FormattedMessage id="trade.trade_list.previous_day.label" defaultMessage="Previous day" />

                  </Link>
                </li>
                <li className="page-item">
                  <Link
                    className="page-link trades-next-day"
                    to={`/properties/${property.id}/trades/history/?date=${after.toISODate()}`}
                    onClick={(event) => (this.setDate(event, after))}
                  >
                    <FormattedMessage id="trade_rule_list.next_day.label" defaultMessage="Next day" />

                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

TradeListTable.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  dateUpdateFunc: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  filter: PropTypes.shape({
    direction: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default injectIntl(createFragmentContainer(
  TradeListTable,
  {
    property: graphql`
      fragment TradeListTable_property on Property
        @argumentDefinitions(
          start: { type: "Timestamp!" }
          finish: { type: "Timestamp!" }
        ) {
        id
        meters(first: 500) {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              rules(first: 500) {
                edges {
                  node {
                    id
                    buyer {
                      tradePoint {
                        id
                        type
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                      userId
                      user {
                        email
                        givenName
                        familyName
                      }
                      communityId
                      residualId
                    }
                    seller {
                      tradePoint {
                        id
                        type
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                      userId
                      user {
                        email
                        givenName
                        familyName
                      }
                      communityId
                      residualId
                    }
                  }
                }
              }
              trades(start: $start, finish: $finish, pageSize: 999999999) {
                edges {
                  node {
                    id
                    ruleId
                    buyerTradePointId
                    sellerTradePointId
                    price
                    value
                    volume
                    direction
                    interval {
                      timestamp
                      length
                    }
                    type
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
