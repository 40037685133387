import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Form, FormFeedback, FormGroup, FormText, Label, Input,
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import isEmail from 'validator/es/lib/isEmail';

import PasswordResetRequestMutation from 'src/mutations/PasswordResetRequestMutation';
import FlashesStore from 'src/stores/FlashesStore';

import AuthContainer, { AUTH_TYPE_FORGOT_PASSWORD } from '../AuthContainer';

class AuthForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailValid: null,
      processing: false,
    };
  }

  handleEmailChange = (e) => {
    const { value: email } = e.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, processing } = this.state;
    const { intl } = this.props;
    FlashesStore.reset();

    if (processing) {
      const msgInprogress = intl.formatMessage({ id: 'auth.auth_forgot_password.form.state.invalid_still_processing', defaultMessage: 'We are still processing your request...' });
      FlashesStore.flash(FlashesStore.INFO, msgInprogress);
      return;
    }

    if (!this.isValid()) {
      const msgInvalidData = intl.formatMessage({ id: 'auth.auth_forgot_password.form.state.invalid_data', defaultMessage: 'Form data not valid. Please see below.' });
      FlashesStore.flash(FlashesStore.ERROR, msgInvalidData);
      return;
    }

    this.setState({ processing: true });

    PasswordResetRequestMutation(
      email,
      this.handleSubmitSuccess,
      this.handleSubmitFailure,
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleSubmitSuccess = (_response) => {
    this.setState({ processing: false });
    const { intl } = this.props;
    const msgSuccess = intl.formatMessage({
      id: 'auth.auth_forgot_password.form.response_message.success',
      defaultMessage: 'Password reset was successful. Check your email shortly.',
    });
    FlashesStore.flash(
      FlashesStore.SUCCESS,
      msgSuccess,
    );

    const { router } = this.props;
    router.push('/login');
  };

  handleSubmitFailure = (error) => {
    this.setState({ processing: false });
    const { intl } = this.props;
    const msgFailure = intl.formatMessage({
      id: 'auth.auth_forgot_password.form.response_message.error',
      defaultMessage: 'Password reset was NOT successful',
    });
    FlashesStore.flash(
      FlashesStore.ERROR,
      `${msgFailure}: ${error.message}.`,
    );
  };

  isValid = () => {
    const { emailValid } = this.state;

    return emailValid;
  };

  render() {
    const { email, emailValid, processing } = this.state;
    const { intl } = this.props;
    const pageTitle = intl.formatMessage({ id: 'auth.auth_forgot_password.page.title', defaultMessage: 'Enosi - request a password reset email' });
    const formTitle = intl.formatMessage({ id: 'auth.auth_forgot_password.form.title', defaultMessage: 'Forgot Password' });
    const emailLabel = intl.formatMessage({ id: 'auth.auth_forgot_password.form.email.label', defaultMessage: 'Your account’s email address' });
    const emailPlaceholder = intl.formatMessage({ id: 'auth.auth_forgot_password.form.email.placeholder', defaultMessage: 'Your account’s email address' });
    const emailFeedbackInvalid = intl.formatMessage({ id: 'auth.auth_forgot_password.form.email.form_feedback.invalid', defaultMessage: 'Email is not valid' });
    const emailHelpText = intl.formatMessage({ id: 'auth.auth_forgot_password.form.email.help_text', defaultMessage: 'Your email address associated with your Enosi account' });
    const submitLabel = intl.formatMessage({ id: 'auth.auth_forgot_password.form.submit.label', defaultMessage: 'Send password reset link' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_FORGOT_PASSWORD} title={formTitle}>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">{emailLabel}</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder={emailPlaceholder}
                onChange={this.handleEmailChange}
                value={email}
                disabled={processing}
                valid={emailValid}
                invalid={emailValid !== null && !emailValid}
              />
              <FormFeedback>{emailFeedbackInvalid}</FormFeedback>
              <FormText>{emailHelpText}</FormText>
            </FormGroup>
            <Button type="submit" color="primary" disabled={processing}>
              {submitLabel}
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthForgotPassword.propTypes = {
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(AuthForgotPassword);
