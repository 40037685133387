import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, CardBody, CardFooter, Form, FormFeedback, FormGroup, FormText, Label, Input,
} from 'reactstrap';
import { createFragmentContainer, graphql } from 'react-relay';
import isLength from 'validator/es/lib/isLength';
import { FormattedMessage, injectIntl } from 'react-intl';

import Address from 'src/components/Address';
import FlashesStore from 'src/stores/FlashesStore';

class PropertySettingsForm extends React.Component {
  static isValidTitle(title) {
    return isLength(title, { min: 3 });
  }

  constructor(props) {
    super(props);

    let title = null;
    if (props && props.property) {
      title = props.property.title;
    }

    this.state = {
      title,
      titleValid: null,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { handlePropertySettingsUpdate, processing } = this.props;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, <FormattedMessage id="form.submission.request.processing" defaultMessage="We are still processing your request..." />);
      return;
    }

    if (!this.validate()) {
      FlashesStore.flash(FlashesStore.ERROR, <FormattedMessage id="form.submission.request.invalid" defaultMessage="Form data not valid. Please see below." />);
      return;
    }

    const { property } = this.props;
    const { id } = property;
    const { title } = this.state;

    const input = { title };

    handlePropertySettingsUpdate(id, input);
  };

  handleTitleChange = (event) => {
    const { value: title } = event.target;
    const titleValid = PropertySettingsForm.isValidTitle(title);

    this.setState({ title, titleValid });
  };

  validate = () => {
    const { title } = this.state;

    const titleValid = PropertySettingsForm.isValidTitle(title);

    this.setState({ titleValid });

    return titleValid;
  };

  render() {
    const { intl, processing, property } = this.props;
    const { address, timezone } = property;
    const { title, titleValid } = this.state;

    const addr = new Address(address);

    const propertyTitlePlaceholder = intl.formatMessage({ id: 'property.property_settings.form.property_title.placeholder', defaultMessage: 'Property title' });
    const propertyAddressPlaceholder = intl.formatMessage({ id: 'property.property_settings.form.property_address.placeholder', defaultMessage: 'Property address' });
    const propertyTimezonePlaceholder = intl.formatMessage({ id: 'property.property_settings.form.property_timezone.placeholder', defaultMessage: 'Property timezone' });

    return (
      <Form className="property-settings" onSubmit={this.handleSubmit} disabled={processing}>
        <Card className="mt-4 mb-4">
          <CardBody>
            <FormGroup>
              <Label for="propertyTitle"><FormattedMessage id="property.property_settings.form.property_title.title" defaultMessage="Title" /></Label>
              <Input type="string" name="propertyTitle" id="propertyTitle" placeholder={propertyTitlePlaceholder} value={title} disabled={processing} onChange={(ev) => (this.handleTitleChange(ev))} valid={titleValid} invalid={titleValid !== null && !titleValid} />
              <FormFeedback><FormattedMessage id="property.property_settings.form.property_title.validation_error" defaultMessage="Titles must be at least 3 characters long" /></FormFeedback>
              <FormText color="muted">
                <FormattedMessage id="property.property_settings.form.property_title.help_text" defaultMessage="A memorable title to refer to the property by." />
              </FormText>
            </FormGroup>

            <FormGroup>
              <Label for="propertyAddress"><FormattedMessage id="property.property_settings.form.address.title" defaultMessage="Address" /></Label>
              <Input type="string" name="propertyAddress" id="propertyAddress" placeholder={propertyAddressPlaceholder} value={addr.string()} disabled />
              <FormText color="muted">
                <FormattedMessage id="property.property_settings.form.address.help_text" defaultMessage="Street address of the property." />
              </FormText>
            </FormGroup>

            <FormGroup>
              <Label for="propertyTimezone"><FormattedMessage id="property.property_settings.form.timezone.title" defaultMessage="Timezone" /></Label>
              <Input type="string" name="propertyTimezone" id="propertyTimezone" placeholder={propertyTimezonePlaceholder} value={timezone} disabled />
              <FormText color="muted">
                <FormattedMessage id="property.property_settings.form.timezone.help_text" defaultMessage="Timezone of the property." />
              </FormText>
            </FormGroup>
          </CardBody>

          <CardFooter>
            <Button disabled={processing}><FormattedMessage id="common.form.submit" defaultMessage="Update" /></Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

PropertySettingsForm.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handlePropertySettingsUpdate: PropTypes.func,
  processing: PropTypes.bool,
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

PropertySettingsForm.defaultProps = {
  handlePropertySettingsUpdate: null,
  processing: false,
  property: null,
};

export default createFragmentContainer(
  injectIntl(PropertySettingsForm),
  {
    property: graphql`
      fragment PropertySettingsForm_property on Property {
        id
        title
        address {
          line1
          line2
          city
          state
          postcode
          country
        }
        timezone
      }
    `,
  },
);
