import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Badge } from 'reactstrap';
import { USER_TYPE_ADMIN, USER_TYPE_CUSTOMER } from '../../../util/constants';

const colorMapping = {
  USER_TYPE_ADMIN: 'secondary',
  USER_TYPE_CUSTOMER: 'primary',
};

/**
 * User type badge
 * @param {object} props
 * @param {USER_TYPE_ADMIN | USER_TYPE_CUSTOMER} props.type
 * @returns {React.ReactComponentElement} - BadgeUserType component
 */
function BadgeUserType({ type }) {
  if (!type) { return (null); }
  const intl = useIntl();

  const color = colorMapping[type] || 'mid';

  const labelAdmin = intl.formatMessage({ id: 'badge.badge_user_type.admin.label', defaultMessage: 'admin' });
  const labelCustomer = intl.formatMessage({ id: 'badge.badge_user_type.customer.label', defaultMessage: 'customer' });
  const labelUnspecified = intl.formatMessage({ id: 'badge.badge_user_type.unspecified.label', defaultMessage: 'unspecified' });

  let label = type.split('_').slice(-1)[0];

  switch (type) {
    case USER_TYPE_ADMIN:
      label = labelAdmin;
      break;
    case USER_TYPE_CUSTOMER:
      label = labelCustomer;
      break;
    default:
      label = labelUnspecified;
  }

  return (
    <Badge color={color} className="badge-outline">{label?.toUpperCase()}</Badge>
  );
}

BadgeUserType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BadgeUserType;
