import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Badge } from 'reactstrap';

const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';

const colorMapping = {
  ACTIVE: 'success',
  INACTIVE: 'danger',
};

/**
 * Active/Inactive badge
 * @param {object} root0
 * @param {number} root0.start
 * @param {number} root0.finish
 * @param {DateTime} root0.at
 * @returns {React.ReactComponentElement} - BadgeActive component
 */
function BadgeActive({ start, finish, at = DateTime.local() }) {
  const intl = useIntl();
  const s = start ? DateTime.fromSeconds(start) : null;
  const f = finish ? DateTime.fromSeconds(finish) : null;

  const labelActive = intl.formatMessage({ id: 'badge.badge_active.active.label', defaultMessage: 'active' });
  const labelInactive = intl.formatMessage({ id: 'badge.badge_active.inactive.label', defaultMessage: 'inactive' });
  let label = labelActive;
  let colorState = ACTIVE;

  if ((s && at <= s) || (f && at > f)) {
    label = labelInactive;
    colorState = INACTIVE;
  }

  const badgeColor = colorMapping[colorState] || 'mid';

  return (
    <Badge color={badgeColor} className="badge-outline">{label.toUpperCase()}</Badge>
  );
}

BadgeActive.propTypes = {
  finish: PropTypes.number,
  start: PropTypes.number,
  at: PropTypes.instanceOf(DateTime),
};

BadgeActive.defaultProps = {
  finish: null,
  start: null,
  at: DateTime.local(),
};

export default BadgeActive;
