import Link from 'found/Link';
import React, { useState } from 'react';
import {
  Collapse, Container, Nav, Navbar, NavbarToggler, NavItem,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import NavigationLogo from 'src/components/Navigation/NavigationLogo';
import { APIConfig } from 'src/config';

const RightNav = styled(Nav).attrs({
  className: 'ms-auto',
})``;

/**
 * Description
 * @returns {React.ReactComponentElement} - AnonymousNavigation component
 */
function AnonymousNavigation() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const cobrand = APIConfig().cobrand();
  const primaryBrand = APIConfig().primaryBrand();

  return (
    <Navbar light expand="lg">
      <Container className="d-flex flex-wrap justify-content-between px-0 px-sm-3">
        <Link to="/" className="navbar-brand">
          <NavigationLogo cobrand={cobrand} primaryBrand={primaryBrand} />
        </Link>
        <NavbarToggler onClick={() => setNavbarOpen(!navbarOpen)} />
        <Collapse isOpen={navbarOpen} navbar>
          <RightNav navbar>
            <NavItem>
              <Link to="/login" className="nav-link">
                <FormattedMessage id="navigation.anonymous_navigation.login.link" defaultMessage="Log in" />
              </Link>
            </NavItem>
          </RightNav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default AnonymousNavigation;
