import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createPaginationContainer, graphql } from 'react-relay';

import { Button } from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminCommunityList component
 */
function AdminCommunityList(props) {
  const loadMore = () => {
    const { relay } = props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.error(error); },
    );
  };

  if (!props) {
    return <Loading />;
  }

  const { relay, viewer } = props;
  const { communities } = viewer;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi Admin - Communities</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Communities' },
        ]}
      />

      <div className="clearfix">
        <h1 className="float-start">Communities</h1>
      </div>

      <div className="mt-4 mb-4 card">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Title</th>
                <th scope="col">ID</th>
                <th scope="col">
                  <abbr title="External identifier">Ext ID</abbr>
                </th>
                <th scope="col">Property Count</th>
              </tr>
            </thead>
            <tbody>
              {
                // properties && <pre>{JSON.stringify(properties, null, 2)}</pre>
                communities && communities.edges && communities.edges.map((edge) => {
                  const { node } = edge;
                  const {
                    id, title, externalIdentifier, active,
                  } = node;
                  const { start, finish } = active;

                  return (
                    <tr key={`admin-community-${id}`}>
                      <td>
                        <BadgeActive start={start} finish={finish} />
                        <br />
                        {start ? DateTime.fromSeconds(start).toISO() : 'the past'}
                        {' to '}
                        {finish ? DateTime.fromSeconds(finish).toISO() : 'the future'}
                      </td>
                      <th scope="row">
                        <Link to={`/admin/communities/${id}`}>
                          <div>{title}</div>
                        </Link>
                      </th>
                      <td colSpan={2}>
                        <UUID uuid={id} />
                        <br />
                        {`(${externalIdentifier})`}
                      </td>
                      <td aria-label="property-count" />
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <Button onClick={loadMore} disabled={!relay.hasMore()}>Load more...</Button>
        </div>
      </div>
    </>
  );
}

AdminCommunityList.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminCommunityList.defaultProps = {
  viewer: null,
};

export default createPaginationContainer(
  AdminCommunityList,
  {
    viewer: graphql`
      fragment AdminCommunityList_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
        communities(
          first: $count
          after: $cursor
        ) @connection(key: "AdminCommunityList_communities") {
          edges {
            cursor
            node {
              id
              title
              externalIdentifier
              active { start finish }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.communities;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query AdminCommunityList_Pagination_Query(
      $count: Int!
      $cursor: String
    ) {
      viewer {
        ...AdminCommunityList_viewer
      }
    }
  `,
  },
);
