/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import { PLATFORM_MODE_REBATE } from 'src/util/constants';

import TradeRuleListTableItem from './TradeRuleListTableItem';

class TradeRuleListTable extends React.Component {
  loadMore = () => {
    const { relay } = this.props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.log(error); },
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      intl, relay, meter, propertyId,
    } = this.props;

    const trades = (!meter.rules || !meter.rules.edges)
      ? [] : meter.rules.edges.map((el) => (el.node));

    const energyCostAbbrTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_list_table.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const energyCostAbbrLabel = intl.formatMessage({ id: 'trade_rule.trade_rule_list_table.energy_cost.abbr.label', defaultMessage: 'c/kWh' });
    const header = APIConfig().MODE === PLATFORM_MODE_REBATE ? <FormattedMessage id="trade_rule.trade_rule_list_table.discount.label" defaultMessage="Discount" /> : <FormattedMessage id="trade_rule.trade_rule_list_table.price.label" defaultMessage="Price" />;

    const priceLabel = (
      <FormattedMessage
        id="trade_rule.trade_rule_list_table.price_or_discount.head.label"
        defaultMessage="{header} {energyCostUnit}"
        values={{
          header,
          energyCostUnit: <abbr title={energyCostAbbrTitle}>{energyCostAbbrLabel}</abbr>,
        }}
      />
    );

    return (
      <div className="mt-4 mb-4 card">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage id="trade_rule.trade_rule_list_table.head.id.label" defaultMessage="ID" /></th>
                <th scope="col"><FormattedMessage id="trade_rule.trade_rule_list_table.head.type.label" defaultMessage="Type" /></th>
                <th scope="col"><FormattedMessage id="trade_rule.trade_rule_list_table.head.state.label" defaultMessage="State" /></th>
                <th scope="col"><FormattedMessage id="trade_rule.trade_rule_list_table.head.direction.label" defaultMessage="Direction" /></th>
                <th scope="col"><FormattedMessage id="trade_rule.trade_rule_list_table.head.counterparty_trader.label" defaultMessage="Counterparty Trader" /></th>
                <th scope="col"><FormattedMessage id="trade_rule.trade_rule_list_table.head.counterparty_trade_point.label" defaultMessage="Counterparty Trade Point" /></th>
                <th scope="col">{priceLabel}</th>
                <th scope="col"><FormattedMessage id="trade_rule.trade_rule_list_table.head.validity.label" defaultMessage="Validity" /></th>
              </tr>
            </thead>
            <tbody>
              {trades
                && trades.map((tradeRule) => (
                  <TradeRuleListTableItem
                    tradePointId={meter.tradePointId}
                    rule={tradeRule}
                    propertyId={propertyId}
                    key={tradeRule.id}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <Button onClick={this.loadMore} disabled={!relay.hasMore()}><FormattedMessage id="trade_rule.trade_rule_list_table.button.load_more.label" defaultMessage="Load more..." /></Button>
        </div>
      </div>
    );
  }
}

TradeRuleListTable.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  meter: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  propertyId: PropTypes.string.isRequired,
};

export default injectIntl(createPaginationContainer(
  TradeRuleListTable,
  {
    meter: graphql`
      fragment TradeRuleListTable_meter on Meter @argumentDefinitions(
        first: { type: "Int"}
        after: { type: "String" }
        last: { type: "Int"}
        before: { type: "String" }
      ) {
        id
        identifier
        title
        tradePointId
        active {
          start
          finish
        }
        rules(
          after: $after
          before: $before
          first: $first
          last: $last
        ) @connection(key: "TradeRuleListTable_rules") {
          edges {
            cursor
            node {
              id
              ...TradeRuleListTableItem_rule
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.meter.rules;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        id: props.meter.id,
      };
    },
    query: graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query TradeRuleListTable_Pagination_Query(
        $id: ID!
        $count: Int
        $cursor: String
      ) {
        meter(id: $id) {
          ...TradeRuleListTable_meter @arguments(
            first: $count
            after: $cursor
          )
        }
      }
    `,
  },
));
