import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createPaginationContainer, graphql } from 'react-relay';
import { Button } from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgeTradeRuleState from 'src/components/Badge/BadgeTradeRuleState';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

import { APIConfig } from 'src/config';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import FlashesStore from 'src/stores/FlashesStore';
import {
  LIST_FILTER_ALL, LOAD_MORE, PLATFORM_MODE_REBATE, REFETCH,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import BrowserProtocol from 'src/util/history';
import { tradeRulePriceRange } from 'src/util/tradeRule';

import AdminTradeRuleListControls from './AdminTradeRuleListControls';
import AdminTradeRuleListTrader from './AdminTradeRuleListTrader';

class AdminTradeRuleList extends React.Component {
  constructor(props) {
    super(props);

    this.dateUpdateFunc = this.dateUpdateFunc.bind(this);
    this.filterFunc = this.filterFunc.bind(this);
    this.loadMore = this.loadMore.bind(this);

    const { variables } = this.props;
    const { state, type, start } = variables;

    this.state = {
      date: start ? DateTime.fromSeconds(start) : null,
      filter: {
        state: state || null,
        type: type || null,
      },
      updating: null,
    };
  }

  doRefetch = () => {
    const { relay, match } = this.props;
    const { location } = match;
    const { pathname } = location;

    const { date, filter } = this.state;
    const { state, type } = filter;

    const variables = { state, type };
    if (date) {
      variables.start = date.startOf('day').toSeconds();
      variables.finish = date.startOf('day').plus({ days: 1 }).toSeconds();
    }

    const queryParameters = new URLSearchParams();
    if (state) {
      queryParameters.append('state', state);
    }
    if (type) {
      queryParameters.append('type', type);
    }
    if (date) {
      queryParameters.append('date', date.toISODate());
    }

    this.setState({ updating: REFETCH });
    relay.refetchConnection(
      50,
      (error) => {
        if (error) {
          FlashesStore.flash(FlashesStore.ERROR, error);
        } else {
          BrowserProtocol.navigate({
            action: 'REPLACE',
            pathname,
            search: queryParameters.toString() ? `?${queryParameters.toString()}` : '',
            hash: '',
          });
        }
        this.setState({ updating: null });
      },
      variables,
    );
  };

  dateUpdateFunc = (date) => {
    const { updating } = this.state;

    if (updating) {
      return;
    }

    this.setState({ date }, this.doRefetch);
  };

  filterFunc = (key, value) => {
    const { filter, updating } = this.state;
    const { state, type } = filter;

    if (updating) {
      return;
    }

    const newFilter = { state, type };
    const newState = { filter: newFilter };

    if (value === LIST_FILTER_ALL) {
      newFilter.state = null;
      newFilter.type = null;
      newState.date = null;
    } else if (filter[key] === value) {
      newFilter[key] = null;
    } else {
      newFilter[key] = value;
    }

    this.setState(newState, this.doRefetch);
  };

  loadMore = () => {
    const { relay } = this.props;
    const { updating } = this.state;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    if (updating) {
      return;
    }

    this.setState({ updating: LOAD_MORE });
    relay.loadMore(
      50,
      (error) => {
        console.log(error);
        this.setState({ updating: null });
      },
    );
  };

  render() {
    if (!this.props) {
      return <Loading />;
    }

    const { relay, viewer } = this.props;
    const { rules } = viewer;

    const { date, filter } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi Admin - Trade Rules</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Admin', path: '/admin' },
            { name: 'Trade Rules' },
          ]}
        />

        <div className="clearfix">
          <h1 className="float-start mb-4">Trade Rules</h1>
        </div>

        <AdminTradeRuleListControls
          date={date}
          dateUpdateFunc={this.dateUpdateFunc}
          filter={filter}
          filterFunc={this.filterFunc}
        />

        <div className="mt-4 mb-4 card">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">ID</th>
                  <th scope="col">State</th>
                  <th scope="col">Type</th>
                  <th scope="col">
                    {APIConfig().MODE === PLATFORM_MODE_REBATE ? 'Discount' : 'Price'}
                  </th>
                  <th scope="col">Buyer</th>
                  <th scope="col">Seller</th>
                </tr>
              </thead>
              <tbody>
                {
                  rules && rules.edges && rules.edges.map((edge) => {
                    const { node } = edge;
                    const {
                      id, tradeType, start,
                      finish, state, buyer, seller,
                    } = node;

                    const priceRange = tradeRulePriceRange(node);

                    return (
                      <tr key={`admin-trade-rule-${id}`}>
                        <td>
                          <BadgeActive start={start} finish={finish} />
                          <br />
                          {(start || finish) && (
                            <>
                              {start ? DateTime.fromSeconds(start).toISO() : 'the past'}
                              {' to '}
                              {finish ? DateTime.fromSeconds(finish).toISO() : 'the future'}
                            </>
                          )}
                        </td>
                        <th scope="row" colSpan={3}>
                          <Link to={`/admin/trade-rules/${id}`}>
                            <UUID uuid={id} />
                          </Link>
                          <br />
                          <BadgeTradeRuleState state={state} />
                          <br />
                          <BadgeTradeType type={tradeType} />
                        </th>
                        <td>
                          <strong>
                            {convertEnergyPrice(priceRange.minimum).toFixed(3)}
                          </strong>
                          {priceRange.maximum !== priceRange.minimum && (
                            <>
                              {' '}
                              to
                              {' '}
                              <strong>
                                {convertEnergyPrice(priceRange.maximum).toFixed(3)}
                              </strong>
                            </>
                          )}
                          {' '}
                          <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        </td>
                        <td>
                          <AdminTradeRuleListTrader trader={buyer} />
                        </td>
                        <td>
                          <AdminTradeRuleListTrader trader={seller} />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            <Button onClick={this.loadMore} disabled={!relay.hasMore()}>Load more...</Button>
          </div>
        </div>
      </>
    );
  }
}

AdminTradeRuleList.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
    refetchConnection: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    viewerUser: PropTypes.shape({
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
    }),
    rules: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            tradeType: PropTypes.string,
            clauses: PropTypes.shape({
              edges: PropTypes.arrayOf(
                PropTypes.shape({
                  node: PropTypes.shape({
                    price: PropTypes.number,
                  }),
                }),
              ),
            }),
            start: PropTypes.number,
            finish: PropTypes.number,
            state: PropTypes.string,
            buyer: PropTypes.shape({
              tradePoint: PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
              }),
              community: PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
                active: PropTypes.shape({
                  start: PropTypes.number,
                  finish: PropTypes.number,
                }),
              }),
              user: PropTypes.shape({
                id: PropTypes.string,
                email: PropTypes.string,
                givenName: PropTypes.string,
                familyName: PropTypes.string,
                active: PropTypes.shape({
                  start: PropTypes.number,
                  finish: PropTypes.number,
                }),
              }),
            }),
            seller: PropTypes.shape({
              tradePoint: PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
              }),
              community: PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
                active: PropTypes.shape({
                  start: PropTypes.number,
                  finish: PropTypes.number,
                }),
              }),
              user: PropTypes.shape({
                id: PropTypes.string,
                email: PropTypes.string,
                givenName: PropTypes.string,
                familyName: PropTypes.string,
                active: PropTypes.shape({
                  start: PropTypes.number,
                  finish: PropTypes.number,
                }),
              }),
            }),
          }),
        }),
      ),
    }),
  }),
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  variables: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

AdminTradeRuleList.defaultProps = {
  viewer: null,
};

export default createPaginationContainer(
  AdminTradeRuleList,
  {
    viewer: graphql`
      fragment AdminTradeRuleList_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
        rules(
          first: $count
          after: $cursor
          state: $state
          type: $type
          start: $start
          finish: $finish
        ) @connection(key: "AdminTradeRuleList_rules") {
          edges {
            cursor
            node {
              id
              tradeType
              clauses {
                edges {
                  node {
                    price
                  }
                }
              }
              start
              finish
              state
              buyer {
                tradePoint { id type }
                community {
                  id
                  title
                  active { start finish }
                }
                user {
                  id
                  email
                  givenName
                  familyName
                  active { start finish }
                }
              }
              seller {
                tradePoint { id type }
                community {
                  id
                  title
                  active { start finish }
                }
                user {
                  id
                  email
                  givenName
                  familyName
                  active { start finish }
                }
              }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.rules;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const variables = {
        count, cursor, state: null, type: null, start: null, finish: null,
      };

      // Set if present
      const {
        id, state, type, start, finish,
      } = fragmentVariables;
      if (id !== undefined && id !== null) { variables.id = id; }
      if (state !== undefined && state !== null) { variables.state = state; }
      if (type !== undefined && type !== null) { variables.type = type; }
      if (start !== undefined && start !== null) { variables.start = start; }
      if (finish !== undefined && finish !== null) { variables.finish = finish; }

      return variables;
    },
    query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query AdminTradeRuleList_Pagination_Query(
      $count: Int!
      $cursor: String
      $state: TradeRuleState
      $type: TradeType
      $start: Timestamp
      $finish: Timestamp
    ) {
      viewer {
        ...AdminTradeRuleList_viewer
      }
    }
  `,
  },
);
