// import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessage, injectIntl } from 'react-intl';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import TradeRuleTimeline from '../TradeRuleTimeline';

class TradeRuleShow extends React.Component {
  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property, rule } = this.props;
    const { id, title } = property;
    const pageTitle = intl.formatMessage(
      { id: 'trade_rule.trade_rule_show.page_title', defaultMessage: 'Enosi - Trade Rule {ruleId} for property {title}' },
      { ruleId: rule.id, title },
    );

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: <FormattedMessage id="trade_rule.trade_rule_show.breadcrumbs.properties.label" defaultMessage=" Properties" />, path: '/properties',
            },
            { name: title, path: `/properties/${id}` },
            { name: <FormattedMessage id="trade_rule.trade_rule_show.breadcrumbs.trade_rules.label" defaultMessage="Trade Rules" />, path: `/properties/${id}/trade-rules` },
            { name: rule.id },
          ]}
        />
        <Heading alias={<FormattedMessage id="trade_rule.trade_rule_show.header.trade_rule.label" defaultMessage="Trade Rule" />} context={<FormattedMessage id="trade_rule.trade_rule_show.header.context.show.label" defaultMessage="Show" />} />
        <TradeRuleTimeline property={property} rule={rule} />
      </>
    );
  }
}

TradeRuleShow.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

TradeRuleShow.defaultProps = {};

export default injectIntl(createFragmentContainer(
  TradeRuleShow,
  {
    property: graphql`
      fragment TradeRuleShow_property on Property {
        id
        title
        ...TradeRuleTimeline_property
      }
    `,
    rule: graphql`
      fragment TradeRuleShow_rule on TradeRule {
        id
        ...TradeRuleTimeline_rule
      }
    `,
  },
));
