import { Duration } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { APIConfig } from 'src/config';

import TimePicker from './TimePicker';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - TimeSpan component
 */
function TimeSpan(props) {
  const {
    start, handleStartChange, finish, handleFinishChange, handleRemove,
  } = props;

  const stepSize = Duration.fromISO(APIConfig().TIME_OF_DAY_STEP_SIZE).as('minutes') || 30;

  return (
    <div className="d-flex align-items-center mb-2">
      <TimePicker
        value={start}
        onChange={handleStartChange}
        min={{ hours: 0, minutes: 0 }}
        max={{ hours: 24, minutes: 0 }}
        step={stepSize}
      />
      <div className="ms-1 me-1">
        -
      </div>
      <TimePicker
        value={finish}
        onChange={handleFinishChange}
        min={{ hours: 0, minutes: 0 }}
        max={{ hours: 24, minutes: 0 }}
        step={stepSize}
      />
      {/* TODO: Should have a title here */}
      <Button color="" onClick={handleRemove}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </div>
  );
}

const timeOfDayShape = PropTypes.shape({
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  nanos: PropTypes.number,
});

TimeSpan.propTypes = {
  start: timeOfDayShape,
  handleStartChange: PropTypes.func.isRequired,
  finish: timeOfDayShape,
  handleFinishChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

TimeSpan.defaultProps = {
  start: null,
  finish: null,
};

export default TimeSpan;
