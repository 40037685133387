import Big from 'big.js';

import { DIRECTIONS, TradeTypes } from 'src/util/constants';
import isNumber from 'src/util/math';

import { getTradeSummary, getTradeType } from './common';

/**
 * Returns the buy/sell aggregated values and volumes of the untraded energy from main data.
 * @param {object} mainData
 * @returns {object} - { buy: { volume: XX, value: XX }, sell: { volume: XX, value: XX }}.
 */
export const getUntraded = (mainData) => {
  const tradeSummary = getTradeSummary(mainData);
  const finalResp = { buy: {}, sell: {} };

  Object.keys(tradeSummary).forEach((dir) => {
    finalResp[dir].value = Big(0);
    finalResp[dir].volume = Big(0);
    const finalIndex = Object.keys(tradeSummary[dir]).length - 1;

    Object.keys(tradeSummary[dir]).forEach((timestamp, index) => {
      const { untradedValue: value, untradedVolume: volume } = tradeSummary[dir][timestamp];
      const aggregatedValue = finalResp[dir].value.plus(value);
      const aggregatedVolume = finalResp[dir].volume.plus(volume);
      finalResp[dir].value = (index === finalIndex) ? Number(aggregatedValue) : aggregatedValue;
      finalResp[dir].volume = (index === finalIndex) ? Number(aggregatedVolume) : aggregatedVolume;
    });
  });

  return finalResp;
};

/**
 * Normalises the trade data, from an array of graphql meter nodes.
 * @param {object} mainData
 * @returns {object} an opinionated data structure for use in the trade summary on
 * the property show page.
 */
export const normaliseTradeData = (mainData) => {
  const resp = {
    buy: {
      contracted: [],
      nominated: [],
      community: [],
      residual: [],
      untraded: [],
    },
    sell: {
      contracted: [],
      nominated: [],
      community: [],
      residual: [],
      untraded: [],
    },
  };

  if (!mainData) {
    return resp;
  }

  const trades = {
    buy: {}, sell: {},
  };

  DIRECTIONS.forEach((dir) => {
    const { data, rules } = mainData[dir];
    if (data) {
      const tradeSetSummaries = Object.keys(data)?.map(
        (timestamp) => data[timestamp].tradeSetSummaries,
      ).filter((item) => item);
      if (tradeSetSummaries?.length === 0) {
        return;
      }
      tradeSetSummaries?.forEach((tradeSet) => {
        Object.keys(tradeSet)?.forEach((ruleId) => {
          const {
            counterfactual, value, volume,
          } = tradeSet[ruleId];
          const finalCounterfactual = isNumber(counterfactual) ? counterfactual : 0;
          if (trades[dir][ruleId]) {
            const {
              value: tradeValue, volume: tradeVolume,
              counterfactual: tradeCounterfactual,
            } = trades[dir][ruleId];
            trades[dir][ruleId] = {
              ...trades[dir][ruleId],
              value: Number(Big(tradeValue).plus(value)),
              volume: Number(Big(tradeVolume).plus(volume)),
              counterfactual: Number(Big(tradeCounterfactual).plus(finalCounterfactual)),
            };
          } else {
            const rule = rules.filter((r) => r.id === ruleId)[0];
            trades[dir][ruleId] = {
              value, volume, counterfactual: finalCounterfactual, rule,
            };
          }
        });
      });
    }
    TradeTypes.forEach((tradeType) => {
      const typeKey = getTradeType(tradeType);
      Object.keys(trades[dir])?.forEach((tradeRuleId) => {
        const type = trades[dir][tradeRuleId]?.rule?.tradeType;
        if (type === tradeType) {
          resp[dir][typeKey].push(trades[dir][tradeRuleId]);
        }
      });
    });
  });

  const untraded = getUntraded(mainData);
  if (untraded?.buy?.volume > 0) {
    resp.buy.untraded.push(untraded.buy);
  }

  if (untraded?.sell?.volume > 0) {
    resp.sell.untraded.push(untraded.sell);
  }
  return resp;
};
