import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {
  FormFeedback, FormGroup, Label,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { Months } from 'src/util/constants';
import { getMonthNames } from 'src/util/i18n/constants';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - SelectTimes component
 */
function SelectMonths(props) {
  const {
    idPrefix, monthsSelected, valid, handleMonthsChange,
  } = props;
  const intl = useIntl();

  const MonthNames = getMonthNames(intl.formatMessage);
  const monthsOptions = Months.map((month) => ({ value: month, label: MonthNames[month] }));
  const monthsValue = monthsSelected.map((month) => ({ value: month, label: MonthNames[month] }));

  const handleChange = (selected) => {
    handleMonthsChange(selected.map((option) => option.value));
  };

  return (
    <FormGroup>
      <Label for={`${idPrefix}MonthsOfYear`}>
        <FormattedMessage id="time_of_use.select_months.months_of_year.form.label" defaultMessage="Months of year" />
      </Label>
      <Select
        isMulti
        name="monthsOfYear"
        id={`${idPrefix}MonthsOfYear`}
        options={monthsOptions}
        value={monthsValue}
        onChange={handleChange}
        className={valid === false ? 'is-invalid' : ''}
        noOptionsMessage={() => intl.formatMessage({ id: 'time_of_use.select_months.form.no_options.message', defaultMessage: 'No options' })}
      />
      <FormFeedback>
        <FormattedMessage id="time_of_use.select_months.form.invalid_months_of_year.message" defaultMessage="Invalid months of year" />
      </FormFeedback>
    </FormGroup>
  );
}

SelectMonths.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  monthsSelected: PropTypes.arrayOf(PropTypes.oneOf(Months)).isRequired,
  valid: PropTypes.bool,
  handleMonthsChange: PropTypes.func.isRequired,
};

SelectMonths.defaultProps = {
  valid: null,
};

export default SelectMonths;
