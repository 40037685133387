import { timeOfUseConditions } from 'src/util/timeOfUse';

/**
 * Given a trade rule, return the maximum and minimum price.
 * @param {object} tradeRule
 * @returns {object} - trade rule price range
 */
// eslint-disable-next-line import/prefer-default-export
export const tradeRulePriceRange = (tradeRule) => {
  const { clauses } = tradeRule;
  const prices = clauses.edges.map((el) => (el.node.price));

  return { maximum: Math.max(...prices), minimum: Math.min(...prices) };
};

/**
 * Returns an array of conditions for the clauses of a trade rule.
 * Note: clause conditions are required to be consistent across the clauses of a
 * single trade rule (from the graphql interface).
 * @param {object} tradeRule
 * @param {import('react-intl').IntlShape} intl
 * @returns {Array} - clause conditions
 */
export const tradeRuleClauseConditions = (tradeRule, intl) => {
  if (!tradeRule) {
    return [];
  }

  const { clauses: clauseEdges } = tradeRule;
  if (!clauseEdges.edges || clauseEdges.edges.length === 0) {
    return [];
  }

  const clauseConditionsAll = clauseEdges.edges.map((el) => {
    const { ignoreDaylightSavings, ignorePublicHolidays, timezone } = el.node;
    return { ignoreDaylightSavings, ignorePublicHolidays, timezone };
  });

  const uniqueClauseConditions = [
    ...new Set(clauseConditionsAll.map((el) => (JSON.stringify(el)))),
  ].map((el) => JSON.parse(el));

  if (uniqueClauseConditions.length !== 1) {
    return [];
  }

  return timeOfUseConditions(uniqueClauseConditions[0], intl);
};
