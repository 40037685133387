import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, FormFeedback, FormGroup, Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import TimeSpan from './TimeSpan';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - SelectTimes component
 */
function SelectTimes(props) {
  const {
    idPrefix, timesSelected, valid, handleTimesChange, generateTimeSpanId,
  } = props;

  return (
    <FormGroup>
      <div>
        <Label for={`${idPrefix}TimesOfDay`}>
          <FormattedMessage id="time_of_use.select_times.form.times_of_day.label" defaultMessage="Times of day" />
        </Label>
        <Button
          color=""
          onClick={() => {
            const newTimes = [
              ...timesSelected,
              { start: null, finish: null, id: generateTimeSpanId() },
            ];
            handleTimesChange(newTimes);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      <div id={`${idPrefix}TimesOfDay`} className={valid === false ? 'is-invalid' : ''}>
        {timesSelected.map(({ start, finish, id }, index) => (
          <TimeSpan
            start={start}
            handleStartChange={(newStart) => {
              const newTimes = [...timesSelected];
              newTimes[index] = { ...newTimes[index], start: newStart };
              handleTimesChange(newTimes);
            }}
            finish={finish}
            handleFinishChange={(newFinish) => {
              const newTimes = [...timesSelected];
              newTimes[index] = { ...newTimes[index], finish: newFinish };
              handleTimesChange(newTimes);
            }}
            handleRemove={() => {
              const newTimes = [...timesSelected];
              newTimes.splice(index, 1);
              handleTimesChange(newTimes);
            }}
            key={id}
          />
        ))}
      </div>
      <FormFeedback>
        <FormattedMessage id="time_of_use.select_times.form.times_of_day.invalid" defaultMessage="Invalid times of day" />
      </FormFeedback>
    </FormGroup>
  );
}

const timeOfDayShape = PropTypes.shape({
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  nanos: PropTypes.number,
});

SelectTimes.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  timesSelected: PropTypes.arrayOf(PropTypes.shape({
    start: timeOfDayShape,
    finish: timeOfDayShape,
    id: PropTypes.string,
  })).isRequired,
  valid: PropTypes.bool,
  handleTimesChange: PropTypes.func.isRequired,
  generateTimeSpanId: PropTypes.func.isRequired,
};

SelectTimes.defaultProps = {
  valid: null,
};

export default SelectTimes;
