import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Row, Col, Card, CardFooter, CardBody, CardTitle, CardSubtitle, CardText,
  DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import {
  FormattedMessage, FormattedPlural, injectIntl,
} from 'react-intl';

import Loading from 'src/components/Loading';
import Time from 'src/enosikit/components/Time/components/Time';
import { APIConfig } from 'src/config';
import isActive from 'src/util/isActive';
import {
  COMMUNITY, PLATFORM_MODE_REBATE, TRADE_TYPE_COMMUNITY, TRADE_RULE_STATE_ACCEPTED,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import username from 'src/util/decorators/username';
import { emptyTradeRulesClassName, getTradeRuleMeterLabel } from 'src/helpers/tradeHelpers';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';

class TradeRuleActiveCommunity extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.communityRules) {
        meterEdge.node.communityRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== TRADE_RULE_STATE_ACCEPTED
            || ruleNode.tradeType !== TRADE_TYPE_COMMUNITY
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    return rules;
  }

  static buyPriceInformation({
    detailsLink, maximum, minimum, mode, units,
  }) {
    if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.buy.rule.price.flat.platform_mode_rebate"
          defaultMessage="Discount of {price} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.buy.rule.price.flat.platform_mode_trade"
          defaultMessage="Buy price of {price} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.buy.rule.price.variable.platform_mode_rebate"
          defaultMessage="Discount of {minimum} to {maximum} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
            minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.buy.rule.price.variable.platform_mode_trade"
          defaultMessage="Buy price of {minimum} to {maximum} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
            minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    return null;
  }

  static sellPriceInformation({
    detailsLink, maximum, minimum, mode, units,
  }) {
    if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.sell.rule.price.flat.platform_mode_rebate"
          defaultMessage="Discount of {price} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.sell.rule.price.flat.platform_mode_trade"
          defaultMessage="Sell price of {price} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.sell.rule.price.variable.platform_mode_rebate"
          defaultMessage="Discount of {minimum} to {maximum} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
            minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
      return (
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_active_community.sell.rule.price.variable.platform_mode_trade"
          defaultMessage="Sell price of {minimum} to {maximum} {units} ({detailsLink})"
          values={{
            detailsLink,
            // TO DO: Implement d3-format locale support (PT-1124)
            maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
            minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
            units,
          }}
        />
      );
    }

    return null;
  }

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property } = this.props;
    const { id: propertyId } = property;
    const { formatMessage } = intl;
    const tradeRules = TradeRuleActiveCommunity.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, COMMUNITY);

    const singleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_community.trade.singular', defaultMessage: 'community trade rule' });
    const multipleTrade = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_community.trade.multiple', defaultMessage: 'community trade rules' });

    return (
      <Card className={`mt-4 mb-4 trade-rules-community${noTradeRulesClass}`}>
        <CardBody>
          <h2><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.title" defaultMessage="Community trades" /></h2>
          <p>
            <FormattedMessage
              id="trade_rule.trade_rule_active.trade_rule_active_community.help_text"
              defaultMessage="Community trades allow you to buy and sell anonymously with other members of your community. The price paid for energy is based on the available supply and the sell and buy price of everyone else in the community."
            />
          </p>

          <Row>
            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.sell.title" defaultMessage="Selling" /></h3>
              <FormattedMessage
                id="trade_rule.trade_rule_active.trade_rule_active_community.sell.count_details"
                defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to sell energy."
                values={{
                  tradeRulesCount: <strong>{tradeRules.sell.length}</strong>,
                  tradeRulesCountLabel: <FormattedPlural
                    value={tradeRules.sell.length}
                    one={singleTrade}
                    other={multipleTrade}
                  />,
                }}
              />

              {tradeRules.sell && tradeRules.sell.length > 0 && tradeRules.sell.map((rule) => {
                const {
                  acceptedAt, acceptedBy, id: ruleId, meter, proposedAt, proposedBy, seller,
                } = rule;
                const { title: tradePointTitle } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {tradePointTitle}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.sell.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_community.sell.rule.meter"
                          defaultMessage="Selling from {meterLabel}"
                          values={{
                            meterLabel: getTradeRuleMeterLabel(seller),
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_community.sell.rule.proposed"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, proposedAt)}
                              unixTimestamp={proposedAt}
                            />,

                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_community.sell.rule.accepted"
                          defaultMessage="Accepted by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(acceptedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, acceptedAt)}
                              unixTimestamp={acceptedAt}
                            />,
                          }}
                        />
                        <br />
                        {
                          TradeRuleActiveCommunity.sellPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_community.sell.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.sell.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.buy.title" defaultMessage="Buying" /></h3>
              <p>
                <FormattedMessage
                  id="trade_rule.trade_rule_active.trade_rule_active_community.buy.count_details"
                  defaultMessage="You have {tradeRulesCount} {tradeRulesCountLabel} to buy energy."
                  values={{
                    tradeRulesCount: <strong>{tradeRules.buy.length}</strong>,
                    tradeRulesCountLabel: <FormattedPlural
                      value={tradeRules.buy.length}
                      one={singleTrade}
                      other={multipleTrade}
                    />,
                  }}
                />
              </p>

              {tradeRules.buy && tradeRules.buy.length > 0 && tradeRules.buy.map((rule) => {
                const {
                  acceptedAt, acceptedBy, id: ruleId, meter, proposedAt, proposedBy, buyer,
                } = rule;
                const { title: tradePointTitle } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {tradePointTitle}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.buy.approved.title" defaultMessage="Approved" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_community.buy.rule.meter"
                          defaultMessage="Buying for {meterLabel}"
                          values={{
                            meterLabel: getTradeRuleMeterLabel(buyer),
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_community.buy.rule.proposed"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, proposedAt)}
                              unixTimestamp={proposedAt}
                            />,
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_active_community.buy.rule.accepted"
                          defaultMessage="Accepted by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(acceptedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, acceptedAt)}
                              unixTimestamp={acceptedAt}
                            />,
                          }}
                        />
                        <br />
                        {
                          TradeRuleActiveCommunity.buyPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_active_community.buy.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_community.buy.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <UncontrolledButtonDropdown className="me-2">
            <DropdownToggle caret color="primary">
              <FormattedMessage id="trade_rule_active.set_community_trades.label" defaultMessage="Set community trade rules" />
            </DropdownToggle>
            <DropdownMenu>
              {property.meters.edges.map((edge) => edge && edge.node && (
                <Link
                  to={`/properties/${property.id}/meters/${edge.node.id}/trade-rules/community/set`}
                  className={`dropdown-item ${!isActive(edge.node.active) ? 'disabled' : ''}`}
                  role="menuitem"
                  key={edge.node.id}
                >
                  {edge.node.title}
                </Link>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveCommunity.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveCommunity.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleActiveCommunity,
  {
    property: graphql`
      fragment TradeRuleActiveCommunity_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
              communityRules: rules(first: 500, type: TRADE_TYPE_COMMUNITY, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
