import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getPropertyLink } from 'src/helpers/tradeHelpers';
import {
  PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
  TRADE_TYPE_RESIDUAL, TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_ACCEPTED,
  TRADE_POINT_TYPE_KNOWN, TRADE_RULE_STATE_CANCELLED, TRADE_RULE_STATE_CLOSED,
  TRADE_RULE_STATE_REJECTED, TRADE_POINT_TYPE_COMMUNITY, TRADE_POINT_TYPE_RESIDUAL,
} from 'src/util/constants';
import UUID from 'src/components/UUID';
import convertEnergyPrice from 'src/util/conversions';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import {
  ActionSpinner, ActionSpinnerWrapper,
  Step, RETAILER_DEFAULT_TRADE_POINT, TRADE_POINT,
} from './tradeRuleConstants';

export const getLastStep = (tradeRule) => {
  if (!tradeRule) {
    return null;
  }
  const {
    acceptedAt, rejectedAt, cancelledAt, closedAt,
  } = tradeRule;

  if (rejectedAt) {
    return TRADE_RULE_STATE_REJECTED;
  }
  if (cancelledAt) {
    return TRADE_RULE_STATE_CANCELLED;
  }
  if (closedAt) {
    return TRADE_RULE_STATE_CLOSED;
  }
  if (acceptedAt) {
    return TRADE_RULE_STATE_ACCEPTED;
  }
  return TRADE_RULE_STATE_PROPOSED;
};

export const getUserName = (userData) => {
  if (!userData) {
    return null;
  }
  const { email, familyName, givenName } = userData;
  const userName = [givenName, familyName].filter(Boolean).join(' ');
  if (userName) {
    return userName;
  }
  if (email) {
    return email;
  }
  return null;
};

export const getTradePointLabel = (trader) => {
  if (!trader) {
    return null;
  }

  const { tradePoint, community } = trader;
  const { id, type, meter } = tradePoint || {};
  let labelTitle = TRADE_POINT;
  if (type === TRADE_POINT_TYPE_KNOWN && !meter && !id) {
    return null;
  }
  if (type === TRADE_POINT_TYPE_KNOWN && meter) {
    labelTitle = getPropertyLink(tradePoint);
  } else if (type === TRADE_POINT_TYPE_COMMUNITY) {
    labelTitle = `${community?.title}`;
  } else if (type === TRADE_POINT_TYPE_RESIDUAL) {
    labelTitle = RETAILER_DEFAULT_TRADE_POINT;
  }

  return (
    <>
      <span className="trade-point-title">
        {labelTitle}
      </span>
      {' '}
      (
      <UUID uuid={id} />
      )

    </>
  );
};

/**
 * Attempt to determine the trade direction based on the buyer and seller details,
 * and a map of meters the user viewing the page has access to.
 * @param {object} buyer
 * @param {object} seller
 * @param {string} actorId the person who proposed the trade rule
 * @returns {object} an object containing the direction of the trade and counterparty
 */
export const getTradeData = (buyer, seller, actorId) => {
  if (actorId === buyer?.user?.id) {
    const buyerData = {
      direction: TRADE_DIRECTION_BUY,
      trader: buyer,
      counterParty: seller,
    };
    return buyerData;
  }
  if (actorId === seller?.user?.id) {
    const sellerData = {
      direction: TRADE_DIRECTION_SELL,
      trader: seller,
      counterParty: buyer,
    };
    return sellerData;
  }
  return { direction: TRADE_DIRECTION_UNSPECIFIED };
};

export const tradeRuleActions = (buttons) => {
  if (!buttons) {
    return null;
  }
  return (
    <Step className="align-items-center">
      <ActionSpinnerWrapper>
        <ActionSpinner type="grow" />
      </ActionSpinnerWrapper>
      <div className="d-flex ms-3 mt-2 mb-2">
        {buttons}
      </div>
    </Step>
  );
};

/**
 * Generates the trade rule(buy) price information for a given trade rule
 * @param {object} root0
 * @param {React.ReactElement} root0.detailsLink - Link to a trade rule (further details)
 * @param {number} root0.maximum
 * @param {number} root0.minimum
 * @param {'PLATFORM_MODE_REBATE'|'PLATFORM_TRADE'} root0.mode
 * @param {'TRADE_TYPE_CONTRACTED'|'TRADE_TYPE_NOMINATED'|'TRADE_TYPE_COMMUNITY'|
 * 'TRADE_TYPE_RESIDUAL'} root0.tradeType
 * @param {React.ReactElement} root0.units
 * @returns {React.ReactComponentElement} - i18n component that carries the (buy) pricing info
 */
export const buyPriceInformation = ({
  detailsLink, maximum, minimum, mode, tradeType, units,
}) => {
  if (mode === PLATFORM_MODE_REBATE && tradeType === TRADE_TYPE_RESIDUAL) {
    return (
      <em><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.buy.agreed_tariffs.label" defaultMessage="Agreed energy tariffs apply" /></em>
    );
  }
  if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.flat.platform_mode_rebate"
        defaultMessage="Discount of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.flat.platform_mode_trade"
        defaultMessage="Buy price of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.variable.platform_mode_rebate"
        defaultMessage="Discount of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.buy.trade_rule_price.variable.platform_mode_trade"
        defaultMessage="Buy price of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  return null;
};

/**
 * Generates the trade rule(sell) price information for a given trade rule
 * @param {object} root0
 * @param {any} root0.detailsLink - Link to a trade rule (further details)
 * @param {any} root0.maximum
 * @param {any} root0.minimum
 * @param {any} root0.mode
 * @param {any} root0.units
 * @param {'TRADE_TYPE_CONTRACTED'|'TRADE_TYPE_NOMINATED'|'TRADE_TYPE_COMMUNITY'|
 * 'TRADE_TYPE_RESIDUAL'} root0.tradeType
 * @returns {React.ReactComponentElement} - i18n component that carries the (sell) pricing info
 */

export const sellPriceInformation = ({
  detailsLink, maximum, minimum, mode, tradeType, units,
}) => {
  if (mode === PLATFORM_MODE_REBATE && tradeType === TRADE_TYPE_RESIDUAL) {
    return (
      <em><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_active_residual.sell.agreed_tariffs.label" defaultMessage="Agreed energy tariffs apply" /></em>
    );
  }
  if (mode === PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.flat.platform_mode_rebate"
        defaultMessage="Discount of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum === maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.flat.platform_mode_trade"
        defaultMessage="Sell price of {price} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          price: <strong>{i18nDecimalFormat(convertEnergyPrice(minimum).toFixed(3))}</strong>,
          units,
        }}
      />
    );
  }

  if (mode === PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.variable.platform_mode_rebate"
        defaultMessage="Discount of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  if (mode !== PLATFORM_MODE_REBATE && minimum !== maximum) {
    return (
      <FormattedMessage
        id="trade_rule.trade_rule_active.trade_rule_helpers.sell.rule.price.variable.platform_mode_trade"
        defaultMessage="Sell price of {minimum} to {maximum} {units} ({detailsLink})"
        values={{
          detailsLink,
          // TO DO: Implement d3-format locale support (PT-1124)
          maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
          minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
          units,
        }}
      />
    );
  }

  return null;
};
