import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, CardBody, CardFooter,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import {
  AllDaysOfWeek, AllMonthsOfYear, AllTimesOfDay, Days, Months,
} from 'src/util/constants';
import { timeOfDayToDuration } from 'src/util/timeOfUse';

import InputPrice from './InputPrice';
import SelectDays from './SelectDays';
import SelectMonths from './SelectMonths';
import SelectTimes from './SelectTimes';

class ClauseEdit extends React.Component {
  static isValidMonths(months) { return (months.length > 0); }

  static isValidDays(days) { return (days.length > 0); }

  static isValidTimes(times) {
    return (
      times.length > 0
      && times.every(ClauseEdit.isValidTimeSpan)
      && ClauseEdit.isValidTimesOverlap(times)
    );
  }

  static isValidTimeSpan({ start, finish }) {
    return (!!start && !!finish && timeOfDayToDuration(finish) >= timeOfDayToDuration(start));
  }

  static isValidTimesOverlap = (times) => {
    const timesDuration = times.map(({ start, finish }) => ({
      start: timeOfDayToDuration(start),
      finish: timeOfDayToDuration(finish),
    }));

    timesDuration.sort((a, b) => (
      Number(a.start) - Number(b.start) || Number(a.finish) - Number(b.finish)
    ));

    return timesDuration.every((timeSpan, index) => (
      index === 0 || timeSpan.start > timesDuration[index - 1].finish
    ));
  };

  constructor(props) {
    super(props);

    const { clause } = this.props;

    const timesOfDay = (clause ? clause.timesOfDay : [AllTimesOfDay])
      .map((timeSpan, index) => ({ ...timeSpan, id: index.toString() }));

    this.state = {
      price: clause ? clause.price : null,
      priceValid: null,
      monthsOfYear: clause ? clause.monthsOfYear : AllMonthsOfYear,
      monthsOfYearValid: null,
      daysOfWeek: clause ? clause.daysOfWeek : AllDaysOfWeek,
      daysOfWeekValid: null,
      timesOfDay,
      timesOfDayValid: null,
    };
    this.lastTimeSpanId = timesOfDay.length - 1;
  }

  generateTimeSpanId = () => {
    this.lastTimeSpanId += 1;
    return this.lastTimeSpanId.toString();
  };

  isValidPrice = (price) => {
    const { priceValidation } = this.props;

    return price !== null && priceValidation(price);
  };

  validate = () => {
    const {
      price, monthsOfYear, daysOfWeek, timesOfDay,
    } = this.state;

    const priceValid = this.isValidPrice(price);
    const monthsOfYearValid = ClauseEdit.isValidMonths(monthsOfYear);
    const daysOfWeekValid = ClauseEdit.isValidDays(daysOfWeek);
    const timesOfDayValid = ClauseEdit.isValidTimes(timesOfDay);

    this.setState({
      priceValid, monthsOfYearValid, daysOfWeekValid, timesOfDayValid,
    });

    return priceValid && monthsOfYearValid && daysOfWeekValid && timesOfDayValid;
  };

  handlePriceChange = (price) => {
    this.setState({ price, priceValid: this.isValidPrice(price) });
  };

  handleMonthsChange = (monthsOfYear) => {
    this.setState({ monthsOfYear, monthsOfYearValid: ClauseEdit.isValidMonths(monthsOfYear) });
  };

  handleDaysChange = (daysOfWeek) => {
    this.setState({ daysOfWeek, daysOfWeekValid: ClauseEdit.isValidDays(daysOfWeek) });
  };

  handleTimesChange = (timesOfDay) => {
    this.setState({ timesOfDay, timesOfDayValid: ClauseEdit.isValidTimes(timesOfDay) });
  };

  handleSet = () => {
    const { setClause } = this.props;
    const {
      price, monthsOfYear, daysOfWeek, timesOfDay,
    } = this.state;

    if (!this.validate()) {
      return;
    }

    const newClause = {
      price,
      monthsOfYear,
      daysOfWeek,
      timesOfDay: timesOfDay.map(({ id: _id, ...timeSpan }) => timeSpan),
    };
    setClause(newClause);
  };

  render() {
    const {
      clause, cancelEdit, priceLabel, priceHelpText,
    } = this.props;
    const {
      priceValid, monthsOfYear, monthsOfYearValid, daysOfWeek, daysOfWeekValid, timesOfDay,
      timesOfDayValid,
    } = this.state;

    return (
      <Card className="mb-2">
        <CardBody>
          <InputPrice
            idPrefix="clauseEdit"
            label={priceLabel}
            helpText={priceHelpText}
            defaultValue={clause ? clause.price : null}
            valid={priceValid}
            handlePriceChange={this.handlePriceChange}
          />
          <SelectMonths
            idPrefix="clauseEdit"
            monthsSelected={monthsOfYear}
            valid={monthsOfYearValid}
            handleMonthsChange={this.handleMonthsChange}
          />
          <SelectDays
            idPrefix="clauseEdit"
            daysSelected={daysOfWeek}
            valid={daysOfWeekValid}
            handleDaysChange={this.handleDaysChange}
          />
          <SelectTimes
            idPrefix="clauseEdit"
            timesSelected={timesOfDay}
            valid={timesOfDayValid}
            handleTimesChange={this.handleTimesChange}
            generateTimeSpanId={this.generateTimeSpanId}
          />
        </CardBody>
        <CardFooter>
          <Button color="primary" className="me-2" onClick={this.handleSet}>
            <FormattedMessage id="time_of_use.clause_edit.set.button.label" defaultMessage="Set" />
          </Button>
          <Button color="" onClick={cancelEdit}>
            <FormattedMessage id="time_of_use.clause_edit.cancel.buton.label" defaultMessage="Cancel" />
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

const timeOfDayShape = PropTypes.shape({
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  nanos: PropTypes.number,
});

ClauseEdit.propTypes = {
  clause: PropTypes.shape({
    price: PropTypes.number,
    monthsOfYear: PropTypes.arrayOf(PropTypes.oneOf(Months)),
    daysOfWeek: PropTypes.arrayOf(PropTypes.oneOf(Days)),
    timesOfDay: PropTypes.arrayOf(PropTypes.shape({
      start: timeOfDayShape,
      finish: timeOfDayShape,
    })),
  }),
  setClause: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  priceLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  priceHelpText: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
      ]),
    ), PropTypes.string, PropTypes.element]),
  priceValidation: PropTypes.func,
};

ClauseEdit.defaultProps = {
  clause: null,
  priceLabel: undefined,
  priceHelpText: undefined,
  priceValidation: () => true,
};

export default ClauseEdit;
