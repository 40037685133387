import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessage } from 'react-intl';

import Loading from 'src/components/Loading';
import TradeRuleListTable from './TradeRuleListTable';

class TradeRuleListTradePoints extends React.Component {
  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { date, property } = this.props;
    const { id, meters } = property;

    return (
      meters && meters.edges && meters.edges.map((meterEdge) => (
        <div key={meterEdge.node.identifier}>
          <h3>
            {meterEdge.node.title}
            <br />
            <small>{meterEdge.node.identifier}</small>
          </h3>
          <TradeRuleListTable meter={meterEdge.node} date={date} propertyId={id} />
        </div>
      ))
    );
  }
}

TradeRuleListTradePoints.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default createFragmentContainer(
  TradeRuleListTradePoints,
  {
    property: graphql`
      fragment TradeRuleListTradePoints_property on Property @argumentDefinitions(
        first: { type: "Int"}
        after: { type: "String" }
        last: { type: "Int"}
        before: { type: "String" }
      ) {
        id
        meters(first:500) {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              ...TradeRuleListTable_meter @arguments(
                first: $first
                after: $after
                last: $last
                before: $before
              )
            }
          }
        }
      }
    `,
  },
);
