import {
  TARIFF_TIME_UNIT_DAY, TARIFF_TIME_UNIT_MONTH, TARIFF_TIME_UNIT_YEAR,
} from '../constants';

const tariffTimeUnit = (key, intl) => {
  if (!intl) {
    switch (key) {
      case TARIFF_TIME_UNIT_DAY:
        return 'day';
      case TARIFF_TIME_UNIT_MONTH:
        return 'month';
      case TARIFF_TIME_UNIT_YEAR:
        return 'year';
      default:
        return null;
    }
  }
  switch (key) {
    case TARIFF_TIME_UNIT_DAY:
      return intl.formatMessage({ id: 'util.decorator.tariff_time_unit.day.label', defaultMessage: 'day' });
    case TARIFF_TIME_UNIT_MONTH:
      return intl.formatMessage({ id: 'util.decorator.tariff_time_unit.month.label', defaultMessage: 'month' });
    case TARIFF_TIME_UNIT_YEAR:
      return intl.formatMessage({ id: 'util.decorator.tariff_time_unit.year.label', defaultMessage: 'year' });
    default:
      return null;
  }
};

export default tariffTimeUnit;
