import Big from 'big.js';

import { APIConfig } from 'src/config';
import {
  COMMUNITY, CONTRACTED, CSV_FILENAME_TIMESTAMP_FORMAT,
  DATA_AGGREGATE_BY_PROPERTY, DATA_GROUP_BY_COUNTERPARTY,
  DATA_AGGREGATE_BY_METER, DATA_GROUP_BY_TRADE_TYPE,
  DIRECTIONS, NOMINATED, PLATFORM_MODE_REBATE,
  RESIDUAL, TRADE_TYPE_CONTRACTED, TRADE_TYPE_COMMUNITY,
  TRADE_TYPE_NOMINATED, TRADE_TYPE_UNSPECIFIED, TRADE_TYPE_RESIDUAL,
  UNTRADED_ENERGY_KEY, VALUE, VOLUME,
} from 'src/util/constants';

import { getTradeTypeLabel } from 'src/util/i18n/helpers';

/**
 * Energy origin label when grouped by counterparty.
 * @param {import('react-intl').IntlShape} intl
 * @param {string | null} key
 * @returns {string | null} - translated label, or key or null
 */
const getEnergyOriginByCounterpartyLabel = (intl, key) => {
  if (!key || !intl) return null;

  if ([TRADE_TYPE_COMMUNITY, TRADE_TYPE_RESIDUAL, UNTRADED_ENERGY_KEY].includes(key)) {
    return getTradeTypeLabel(intl, key);
  }

  return key;
};

/**
 * Energy origin label when grouped by trade type.
 * @param {import('react-intl').IntlShape} intl
 * @param {string | null} key
 * @returns {string | null} - translated label, or key or null
 */
const getEnergyOriginByTradeTypeLabel = (intl, key) => {
  if (!key || !intl) return null;

  return getTradeTypeLabel(intl, key);
};

/**
 * Takes in a key and the intl object and returns the translated key
 * for the PropertyShowChartCards
 * @param {import('react-intl').IntlShape} intl
 * @param {TRADE_TYPE_COMMUNITY | TRADE_TYPE_RESIDUAL| UNTRADED_ENERGY_KEY} key
 *  @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {string | null} - translated label, or key or null
 */
export const getEnergyOriginLabel = (intl, key, chartView) => {
  if (!key || !intl || !chartView) return null;
  const { groupBy } = chartView;

  if (groupBy === DATA_GROUP_BY_TRADE_TYPE) { return getEnergyOriginByTradeTypeLabel(intl, key); }

  return getEnergyOriginByCounterpartyLabel(intl, key);
};

/**
 * Returns the trade type based on the given type.
 * @param {TRADE_TYPE_CONTRACTED | TRADE_TYPE_NOMINATED
 * | TRADE_TYPE_COMMUNITY | TRADE_TYPE_RESIDUAL | string} type - The type of trade.
 * @returns {TRADE_TYPE_UNSPECIFIED | CONTRACTED
 * | NOMINATED | COMMUNITY |  RESIDUAL} - The corresponding trade type.
 */
export const getTradeType = (type) => {
  if (!type) {
    return TRADE_TYPE_UNSPECIFIED;
  }
  switch (type) {
    case TRADE_TYPE_CONTRACTED:
      return CONTRACTED;
    case TRADE_TYPE_NOMINATED:
      return NOMINATED;
    case TRADE_TYPE_COMMUNITY:
      return COMMUNITY;
    default: // TRADE_TYPE_RESIDUAL
      return RESIDUAL;
  }
};

/**
 * Provides the filename for the downlodable file in property dashboard
 * @param {string} title - property title
 * @param {object} timeRange - time range of the data (meter or trade)
 * e.g {start: DateTime, finish: Datetime}
 * @param {string} type - meter or trade
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {string} - file name (data download).
 */
export const getFileName = (title, timeRange, type, intl) => {
  if (!title && !timeRange) {
    return null;
  }
  const { start, finish } = timeRange;
  const startTime = start.toFormat(CSV_FILENAME_TIMESTAMP_FORMAT);
  const finishTime = finish.toFormat(CSV_FILENAME_TIMESTAMP_FORMAT);
  let fileName;

  switch (type) {
    case 'meter':
      fileName = intl.formatMessage({ id: 'property.property_show.data.meter_data_csv_filename', defaultMessage: 'Enosi Powertracer - {title} - meter data - {startTime} to {finishTime}' }, {
        title, startTime, finishTime,
      });
      break;
    case 'trade':
      fileName = intl.formatMessage({ id: 'property.property_show.data.trade_data_csv_filename', defaultMessage: 'Enosi Powertracer - {title} - trade data - {startTime} to {finishTime}' }, {
        title, startTime, finishTime,
      });
      break;
    default:
      fileName = intl.formatMessage({ id: 'property.property_show.data.json_file_name', defaultMessage: 'Enosi Powertracer - {title} - {startTime} to {finishTime}' }, {
        title, startTime, finishTime,
      });
  }

  return fileName;
};

/**
 * Prepares the user name from the user object
 * concatenates first and last name if available or returns email
 * @param {object} user
 * @returns {string} - user name.
 */
export const getUserName = (user) => {
  if (!user) {
    return null;
  }
  const { familyName, givenName, email } = user;
  let userName = null;
  if (familyName || givenName) {
    userName = `${givenName || ''} ${familyName || ''}`;
  } else {
    userName = email || null;
  }
  return userName?.trim();
};

/**
 * Sums specific element in an object
 * @param {Array} data - tradeSetSummaries, untradedDataAggregates
 * @param {string} key  - value, volume, carbon
 * @returns {Big | NaN} Big number - sum of the specific element
 */
export const sumValuesByObjKeys = (data, key) => {
  if (!data) return NaN;

  return (data.reduce(
    (acc, element) => (element[key] ? acc.plus(element[key]) : acc),
    Big(0),
  ));
};

/**
 * Transforms the main data into the format required for the
 * trade summary component on the property dashboard.
 * @param {object} mainData
 * @returns {object} - trade summary
 */
export const getTradeSummary = (mainData) => {
  const finalResp = {
    buy: {},
    sell: {},
  };
  const IS_MODE_REBATE = APIConfig().MODE === PLATFORM_MODE_REBATE;

  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) {
      return;
    }
    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const {
        meterDataAggregates: meters,
        tradeSetSummaries: trades,
        untradedDataAggregates: untradedData,
      } = mainData[dir].data[timestamp] || {};
      if (!meters) {
        return;
      }
      const meterValue = sumValuesByObjKeys(Object.values(meters), VALUE);
      const untradedVolume = sumValuesByObjKeys(Object.values(untradedData), VOLUME);
      const untradedValue = sumValuesByObjKeys(Object.values(untradedData), VALUE);
      const energy = Number(meterValue) ? Big(meterValue) : Big(0);

      let tradedEnergy = Big(0);
      let tradedValue = Big(0);
      let totalResidual = Big(0);
      let rebatedEnergy = Big(0);
      let netDiffValue = Big(0);

      if (trades) {
        Object.keys(trades)?.forEach((tradeId) => {
          const {
            counterfactual, type, value = 0, volume = 0,
          } = trades[tradeId] || {};
          tradedEnergy = tradedEnergy.plus(volume);
          tradedValue = tradedValue.plus(value);
          const isResidual = type === TRADE_TYPE_RESIDUAL;
          if (isResidual) {
            netDiffValue = netDiffValue.plus(value);
          } else if (counterfactual && Number(counterfactual)) {
            netDiffValue = netDiffValue.plus(counterfactual);
          }
          if (IS_MODE_REBATE && isResidual) {
            totalResidual = totalResidual.plus(volume);
          }
        });
      }

      if (IS_MODE_REBATE) {
        rebatedEnergy = tradedEnergy.minus(totalResidual);
      }

      finalResp[dir][timestamp] = {
        energy: Number(energy),
        tradedEnergy: Number(tradedEnergy),
        tradedValue: Number(tradedValue),
        rebatedEnergy: Number(rebatedEnergy),
        netDiffValue: Number(netDiffValue),
        untradedVolume,
        untradedValue,
      };
    });
  });
  return finalResp;
};
