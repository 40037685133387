import { commitMutation, graphql } from 'react-relay';
import environment from 'src/util/environment';

const mutation = graphql`
  mutation CloseNominatedTradeMutation($id: ID!) {
    closeNominatedTrade(id: $id) {
      id
    }
  }
`;

const CloseNominatedTradeMutation = (id, onCompleted, onError, formatMessage) => {
  const variables = { id };
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => { onCompleted(response, formatMessage); },
      onError,
      updater: (store) => {
        // Get the current user record from the store
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_CLOSED', 'state');
      },
    },
  );
};

export default CloseNominatedTradeMutation;
