import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';
import { useIntl } from 'react-intl';

import { TRADE_POINT_TYPE_COMMUNITY, TRADE_POINT_TYPE_KNOWN, TRADE_POINT_TYPE_RESIDUAL } from 'src/util/constants';

/**
 * Trade point type badge
 * @param {object} props
 * @param {TRADE_POINT_TYPE_COMMUNITY |
 * TRADE_POINT_TYPE_KNOWN |
 * TRADE_POINT_TYPE_RESIDUAL} props.type
 * @returns {React.ReactComponentElement} - BadgeTradePointType component
 */
function BadgeTradePointType({ type }) {
  if (!type) { return (null); }

  const intl = useIntl();

  const tradePointKnownLabel = intl.formatMessage({ id: 'badge.badge_trade_point_type.known.label', defaultMessage: 'known' });
  const tradePointCommunityLabel = intl.formatMessage({ id: 'badge.badge_trade_point_type.community.label', defaultMessage: 'community' });
  const tradePointResidualLabel = intl.formatMessage({ id: 'badge.badge_trade_point_type.residual.label', defaultMessage: 'retailer default' });

  let label;
  switch (type) {
    case TRADE_POINT_TYPE_KNOWN:
      label = tradePointKnownLabel;
      break;
    case TRADE_POINT_TYPE_COMMUNITY:
      label = tradePointCommunityLabel;
      break;
    case TRADE_POINT_TYPE_RESIDUAL:
      label = tradePointResidualLabel;
      break;
    default:
      label = '';
  }

  return (
    <Badge color="mid" className="badge-outline">{label?.toUpperCase()}</Badge>
  );
}

BadgeTradePointType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BadgeTradePointType;
