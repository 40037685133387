import { DateTime } from 'luxon';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminMeterShowTariff component
 */
function AdminMeterShowTariff(props) {
  if (!props) {
    return <tr><td aria-label="loading" colSpan={4}><Loading /></td></tr>;
  }

  const { tariff } = props;
  const {
    id, identifier, active, meter,
  } = tariff;
  const { start, finish } = active;
  const { id: meterId } = meter;

  return (
    <tr key={`tariff-${id}`}>
      <th scope="row" className="text-right">
        <Link to={`/admin/tariffs/${id}`}>
          <UUID uuid={id} />
        </Link>
      </th>
      <td>{identifier}</td>
      <td>
        <BadgeActive start={start} finish={finish} />
        <br />
        {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
      <td>
        <UUID uuid={meterId} />
      </td>
    </tr>
  );
}

AdminMeterShowTariff.propTypes = {
  tariff: PropTypes.shape({
    id: PropTypes.string,
    identifier: PropTypes.string,
    active: PropTypes.shape({
      start: PropTypes.number,
      finish: PropTypes.number,
    }),
    meter: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

AdminMeterShowTariff.defaultProps = {
  tariff: null,
};

export default AdminMeterShowTariff;
