import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';

import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import convertEnergyPrice from 'src/util/conversions';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminTariffShowVolumeCharge component
 */
function AdminTariffShowVolumeCharge(props) {
  if (!props) {
    return <tr><td aria-label="loading" colSpan={7}><Loading /></td></tr>;
  }

  const { volumeCharge } = props;
  const {
    id, identifier, externalIdentifier, title,
    rate, importFlag, exportFlag, ignorePublicHolidays,
  } = volumeCharge;

  return (
    <tr key={`tariff-volume-charge-${id}`}>
      <th scope="row" colSpan={2}>
        <Link to={`/admin/volume-charges/${id}`}>
          {title}
        </Link>
        <br />
        {identifier}
      </th>
      <td colSpan={2}>
        <UUID uuid={id} />
        <br />
        {externalIdentifier}
      </td>
      <td>
        {convertEnergyPrice(rate).toFixed(3)}
        {' '}
        <abbr title="cents per kilowatt hour">c/kWh</abbr>
      </td>
      <td>
        {importFlag && <div>Import</div>}
        {exportFlag && <div>Export</div>}
        {ignorePublicHolidays && <div>IgnorePublicHolidays</div>}
      </td>
    </tr>
  );
}

AdminTariffShowVolumeCharge.propTypes = {
  volumeCharge: PropTypes.shape({
    id: PropTypes.string,
    identifier: PropTypes.string,
    externalIdentifier: PropTypes.string,
    title: PropTypes.string,
    rate: PropTypes.number,
    importFlag: PropTypes.bool,
    exportFlag: PropTypes.bool,
    ignorePublicHolidays: PropTypes.bool,
  }),
};

AdminTariffShowVolumeCharge.defaultProps = {
  volumeCharge: null,
};

export default AdminTariffShowVolumeCharge;
