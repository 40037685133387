import { Fragment } from 'react';
import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation CancelNominatedTradeMutation($id: ID!) {
    cancelNominatedTrade(id: $id) {
      id
    }
  }
`;

/**
 * Cancel nominated trade mutation.
 * @param {object} id UUID of the trade rule to be cancelled.
 * @param {Function} onCompleted Function to call on success.
 * @param {Function} onError Function to call on error.
 * @param {string|Fragment} formatMessage message to return?
 */
const CancelNominatedTradeMutation = (id, onCompleted, onError, formatMessage) => {
  const variables = { id };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => { onCompleted(response, formatMessage); },
      onError,
      updater: (store) => {
        // Get the current user record from the store
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_CANCELLED', 'state');
      },
    },
  );
};

export default CancelNominatedTradeMutation;
