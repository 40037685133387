import FlashesStore from 'src/stores/FlashesStore';

/**
 * checks if user is authenticated
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @param {object} viewer - {id, properties, viewerUser, __fragmentOwner,
 * __fragments, __id, __isWithinUnmatchedTypeRefinement }
 * @param {string} type - ex: "USER_TYPE_ADMIN"
 * @param {object} router - router object
 * @returns {boolean} returns whether the user is authenticated or not
 */
const authenticateUser = (intl, viewer, type, router) => {
  if (!viewer || !viewer.id) {
    const errorLoginMessage = intl.formatMessage({ id: 'auth.authenticate_user.error_login_message', defaultMessage: 'Please log in to continue' });
    FlashesStore.flash(FlashesStore.ERROR, errorLoginMessage);
    router.replace('/login');
    return false;
  }

  if (type === null) {
    return true;
  }

  // Authorises an admin
  const { viewerUser } = viewer;
  if (viewerUser && viewerUser.type === type) {
    return true;
  }

  const errorNotAuthorisedMessage = intl.formatMessage({ id: 'auth.authenticate_user.error_not_authorised_message', defaultMessage: 'You are not authorised to be here' });

  FlashesStore.flash(FlashesStore.ERROR, errorNotAuthorisedMessage);
  router.replace('/properties');
  return false;
};

export default authenticateUser;
