import { format } from 'd3-format';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  TARIFF_TIME_UNIT_UNSPECIFIED, TariffTimeUnitNames,
  TARIFF_TYPE_ENERGY, TARIFF_TYPE_ENVIRONMENTAL, TARIFF_TYPE_NETWORK, TARIFF_TYPE_OTHER,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import tariffTimeUnit from 'src/util/decorators/units';
import { getLocale, i18nDecimalFormat } from 'src/util/i18n/handler';
import { timeOfUseDetailsI18n, timeOfUseConditions } from 'src/util/timeOfUse';

const locale = getLocale();

class TariffInfo extends React.Component {
  static activeTimerange(intl, timeRange, timezone) {
    const { start, finish } = timeRange;
    const tsFormat = 'd LLLL yyyy, HH:mm ZZZZZ'; // For example: "1 March 2022, 23:45 Australian Eastern Daylight Time"
    let startTS;
    let finishTS;
    if (start && !finish) {
      startTS = DateTime.fromSeconds(start).setZone(timezone)
        .setLocale(locale).toFormat(tsFormat);
      const startOnlyLabel = intl.formatMessage({ id: 'tariff.tariff_info.active_timerange.start_only_label', defaultMessage: 'Valid from {start} to present' }, { start: startTS });
      return startOnlyLabel;
    }

    if (!start && finish) {
      finishTS = DateTime.fromSeconds(finish).setZone(timezone)
        .setLocale(locale).toFormat(tsFormat);
      const finishOnlyLabel = intl.formatMessage({ id: 'tariff.tariff_info.active_timerange.finish_only_label', defaultMessage: 'Valid to {finish}' }, { finish: finishTS });
      return finishOnlyLabel;
    }

    startTS = DateTime.fromSeconds(start).setZone(timezone)
      .setLocale(locale).toFormat(tsFormat);
    finishTS = DateTime.fromSeconds(finish).setZone(timezone)
      .setLocale(locale).toFormat(tsFormat);

    const startAndFinishLabel = intl.formatMessage({ id: 'tariff.tariff_info.active_timerange.start_and_finish_label', defaultMessage: 'Valid from {start} to {finish}' }, { start: startTS, finish: finishTS });
    return startAndFinishLabel;
  }

  /**
   * Return the standing charge details as a translated HTML string.
   * @param {import('react-intl').IntlShape} intl - International message formatting.
   * @param {object} volumeCharge - The volume charge.
   * @returns {React.ReactElement | null} The translated block tariff information wrap in a div.
   * or null.
   */
  static blockTariffInformation(intl, volumeCharge) {
    const { blockUnit, blockMinimum, blockMaximum } = volumeCharge;

    if (blockUnit == null || blockUnit === TARIFF_TIME_UNIT_UNSPECIFIED) {
      return null;
    }

    // Base unit value is Wh, presentation is in kWh.
    // TO DO: Implement d3-format locale support (PT-1124)
    const formatKwh = (value) => i18nDecimalFormat(format('.3~f')(value / 1000));

    let text = '';
    const tariffTimeUnitName = TariffTimeUnitNames[blockUnit];
    const energyUnitTitle = intl.formatMessage({ id: 'tariff.tariff_info.energy_unit.title', defaultMessage: 'kilowatt hours' });
    const energyUnitLabel = intl.formatMessage({ id: 'tariff.tariff_info.energy_unit.label', defaultMessage: 'kWh' });
    const energyUnit = <abbr title={energyUnitTitle}>{energyUnitLabel}</abbr>;

    if (blockMinimum != null) {
      if (blockMaximum != null) {
        const formattedMinimum = formatKwh(blockMinimum);
        const formattedMaximum = formatKwh(blockMaximum);

        text = intl.formatMessage({
          id: 'tariff.tariff_info.volume_charge.block.maximum_and_minimum_text',
          defaultMessage: 'Between {minimum} and {maximum} {energyUnit}/{blockUnit}',
        }, {
          minimum: formattedMinimum,
          maximum: formattedMaximum,
          energyUnit,
          blockUnit: tariffTimeUnitName,
        });
      } else {
        const formattedMininum = formatKwh(blockMinimum);
        text = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.block.minimum_only_text', defaultMessage: 'Beyond {minimum} {energyUnit}/{blockUnit}' }, { minimum: formattedMininum, energyUnit, blockUnit: tariffTimeUnitName });
      }
    } else if (blockMaximum != null) {
      const formattedMaximum = formatKwh(blockMaximum);

      text = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.block.maximum_only_text', defaultMessage: 'Up to {maximum} {energyUnit}/{blockUnit}' }, { maximum: formattedMaximum, energyUnit, blockUnit: tariffTimeUnitName });
    }

    return text !== '' ? <div data-testid="block-tariff">{text}</div> : null;
  }

  static chargeDirection(volumeCharge) {
    const { importFlag, exportFlag } = volumeCharge;
    if (importFlag) {
      if (exportFlag) {
        return <FormattedMessage id="tariff.tariff_info.volume_charge.direction.import_and_export_text" defaultMessage="both import and export" />;
      }
      return <FormattedMessage id="tariff.tariff_info.volume_charge.direction.import_only_text" defaultMessage="import only" />;
    }
    return <FormattedMessage id="tariff.tariff_info.volume_charge.direction.export_only_text" defaultMessage="export only" />;
  }

  static chargeLabel(tariffType) {
    switch (tariffType) {
      case TARIFF_TYPE_ENERGY:
        return <FormattedMessage id="tariff.tariff_info.tariff_type.energy.label" defaultMessage="Energy Charge" />;
      case TARIFF_TYPE_NETWORK:
        return <FormattedMessage id="tariff.tariff_info.tariff_type.network.label" defaultMessage="Network Charge" />;
      case TARIFF_TYPE_ENVIRONMENTAL:
        return <FormattedMessage id="tariff.tariff_info.tariff_type.environmental.label" defaultMessage="Environmental Charge" />;
      case TARIFF_TYPE_OTHER:
        return <FormattedMessage id="tariff.tariff_info.tariff_type.other.label" defaultMessage="Other Charge" />;
      default:
        return <FormattedMessage id="tariff.tariff_info.tariff_type.charge.label" defaultMessage="Charge" />;
    }
  }

  /**
   * Return the time of use conditions wrapped in a div.
   * @param {import('react-intl').IntlShape} intl - International message formatting.
   * @param {object} volumeCharge - The volume charge.
   * @returns {React.ReactElement | null} The returned time of use conditions wrap in a div or null.
   */
  static timeOfUseConditions(intl, volumeCharge) {
    const conditions = timeOfUseConditions(volumeCharge, intl);
    if (conditions.length === 0) {
      return null;
    }

    return (<div>{`(${intl.formatList(conditions, { type: 'unit' })})`}</div>);
  }

  /**
   * Return the standing charge details as a translated HTML string.
   * @param {import('react-intl').IntlShape} intl - International message formatting.
   * @param {string} key - The standing charge's time unit.
   * @param {number} rate - The standing charge's rate in currency per time unit.
   * @returns {string} The returned HTML string as per message format.
   */
  static standingCharge(intl, key, rate) {
    const formattedRate = <FormattedMessage id="tariff.tariff_info.standing_charge.rate_label" defaultMessage="{n, number, :: .000 currency/AUD}" values={{ n: convertEnergyPrice(rate) }} />;
    const timeUnit = tariffTimeUnit(key, intl);
    const timeUnitAbbrText = `/${timeUnit} `;
    const timeUnitAbbrTitle = `${intl.formatMessage({ id: 'tariff.tariff_info.standing_charge.tariff_time_unit.abbr.title', defaultMessage: 'per {timeUnit}' }, { timeUnit })} `;

    return intl.formatMessage({ id: 'tariff.tariff_info.standing_charge.tariff_time_unit.text', defaultMessage: '{formattedRate} {perTimeUnit}' }, {
      formattedRate,
      perTimeUnit: <abbr title={timeUnitAbbrTitle}>{timeUnitAbbrText}</abbr>,
    });
  }

  /**
   * The volume charge details as a translated HTML string.
   * @param {import('react-intl').IntlShape} intl - International message formatting.
   * @param {number} rate - The volume charge's rate in currency per watt-hour.
   * @returns {string} The returned HTML string as per message format.
   */
  static volumeCharge(intl, rate) {
    const formattedRate = <FormattedMessage id="tariff.tariff_info.volume_charge.rate_label" defaultMessage="{n, number, :: .000 currency/AUD}" values={{ n: convertEnergyPrice(rate) }} />;
    const unitAbbrTitle = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.abbr.title', defaultMessage: 'cents per kilowatt hour' });
    const unitAbbrText = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.abbr.text', defaultMessage: 'c/kWh' });

    return intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.text', defaultMessage: '{formattedRate} {unit}' }, { formattedRate, unit: <abbr title={unitAbbrTitle}>{unitAbbrText}</abbr> });
  }

  render() {
    const { tariff, intl } = this.props;

    return (
      <div className="mt-4 mb-4">
        <h4>
          {tariff.title}
          <br />
          <small>{tariff.identifier}</small>
        </h4>

        <div className="card">
          <div className="card-header">
            {TariffInfo.activeTimerange(intl, tariff.active, tariff.property.timezone)}
          </div>
          <table className="table m-0">
            <thead>
              <tr>
                <th scope="col">{TariffInfo.chargeLabel(tariff.tariffType)}</th>
                <th scope="col">
                  <FormattedMessage id="tariff.tariff_info.details.label" defaultMessage="Details" />
                </th>
              </tr>
            </thead>
            <tbody>
              {tariff.standingCharges && tariff.standingCharges.edges.map((elv) => {
                const { node: charge } = elv;
                const {
                  id, title, identifier, rate, timeUnit,
                } = charge || {};
                return (
                  <tr key={id}>
                    <th scope="row">
                      {title}
                      <br />
                      <small>{identifier}</small>
                    </th>
                    <td>
                      {TariffInfo.standingCharge(intl, timeUnit, rate)}
                    </td>
                  </tr>
                );
              })}

              {// eslint-disable-next-line max-len
                tariff.volumeCharges && tariff.volumeCharges.edges.slice().sort((a, b) => (b.node.rate - a.node.rate)).map((elv) => {
                  const { node: charge } = elv;
                  const {
                    id, title, identifier, rate, importFlag, exportFlag,
                  } = charge || {};
                  let volumeChargeRate;
                  const rateElement = TariffInfo.volumeCharge(intl, rate);
                  if (!importFlag && !exportFlag) {
                    volumeChargeRate = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.rate.invalid.text', defaultMessage: 'invalid charge of {rateElement} as applies to neither import nor export' }, { rateElement });
                  }

                  if (importFlag && !exportFlag) {
                    volumeChargeRate = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.rate.import_only.text', defaultMessage: '{rateElement} import only' }, { rateElement });
                  }

                  if (!importFlag && exportFlag) {
                    volumeChargeRate = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.rate.export_only.text', defaultMessage: '{rateElement} export only' }, { rateElement });
                  }

                  if (importFlag && exportFlag) {
                    volumeChargeRate = intl.formatMessage({ id: 'tariff.tariff_info.volume_charge.rate.import_and_export.text', defaultMessage: '{rateElement} both import and export' }, { rateElement });
                  }

                  return (
                    <tr key={id}>
                      <th scope="row">
                        {title}
                        <br />
                        <small>{identifier}</small>
                      </th>
                      <td>
                        <div data-testid="tariff-details">
                          {volumeChargeRate}
                        </div>
                        <div>
                          {timeOfUseDetailsI18n(intl, charge)}
                        </div>
                        {TariffInfo.blockTariffInformation(intl, charge)}
                        {TariffInfo.timeOfUseConditions(intl, charge)}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

TariffInfo.propTypes = {
  tariff: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(createFragmentContainer(
  TariffInfo,
  {
    tariff: graphql`
      fragment TariffInfo_tariff on Tariff {
      id
      identifier
      title
      description
      tariffType
        active { start finish }
        property {
        timezone
      }
      standingCharges(first: 500) {
          edges {
            node {
            id
            externalIdentifier
            identifier
            title
            description
            rate
            timeUnit
          }
        }
      }
      volumeCharges(first: 500) {
          edges {
            node {
            id
            externalIdentifier
            identifier
            title
            description
            rate
            volumeUnit
            importFlag
            exportFlag
            ignorePublicHolidays
            ignoreDaylightSavings
            timezone
            monthsOfYear
            daysOfWeek
              timesOfDay {
                start { hours minutes seconds }
                finish { hours minutes seconds }
            }
            blockUnit
            blockMinimum
            blockMaximum
          }
        }
      }
    }
    `,
  },
));
