// Supporting nullable formatted messages.
const nullableFormattedMessage = (({ intl, id, message }) => {
  if (!intl) {
    return null;
  }
  if (!intl.messages) {
    return null;
  }
  if (!intl.messages[id] || intl.messages[id] === id) {
    return null;
  }

  return message;
});

export default nullableFormattedMessage;
