import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import Chart from 'src/enosikit/components/Chart';
import { getTotalCarbonEmission } from 'src/lib/carbondata/helpers';
import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
  SOURCE, SOURCE_HISTORIAN, SOURCE_TRADES, TIME_ZONE_SYSTEM,
  UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY,
  UNTRADED_ENERGY_KEY,
} from 'src/util/constants';

import PropertyShowChartCards from './PropertyShowChartCards';
import {
  buildChartTradeData, getTradeCardsData, getChartSummaryData,
  buildChartMeterData, buildMeterCardsData,
} from './helpers/propertyShowChart';

class PropertyShowChart extends React.Component {
  constructor(props) {
    super(props);

    this.chartRef = React.createRef();

    this.state = {
      hoverKeys: [],
      selectedKeys: [],
      tooltipTimestamp: null,
      chartSize: { height: 0, width: 0 },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setChartBounds);
    this.setChartBounds();
  }

  componentDidUpdate(prevProps) {
    const { selectedKeys } = this.state;
    const { source } = this.props;
    const { source: prevSource } = prevProps;
    if (source !== prevSource && selectedKeys.length > 0) {
      this.setState({ selectedKeys: [] });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setChartBounds);
  }

  setChartBounds = () => {
    this.setState(() => {
      // Note this excludes padding so ...
      let { clientHeight: height, clientWidth: width } = this.chartRef.current;
      // ...need to get the computed padding.
      const {
        paddingTop, paddingBottom, paddingLeft, paddingRight,
      } = window.getComputedStyle(this.chartRef.current);

      if (paddingTop !== '') { height -= parseFloat(paddingTop); }
      if (paddingBottom !== '') { height -= parseFloat(paddingBottom); }
      if (paddingLeft !== '') { width -= parseFloat(paddingLeft); }
      if (paddingRight !== '') { width -= parseFloat(paddingRight); }

      return { chartSize: { height, width } };
    });
  };

  cardsHoverFunc = (key, hover) => {
    this.setState((prevState) => {
      const newHoverKeys = prevState.hoverKeys;
      if (hover) {
        if (!newHoverKeys.includes(key)) {
          newHoverKeys.push(key);
        }
      } else if (newHoverKeys.includes(key)) {
        newHoverKeys.splice(newHoverKeys.indexOf(key), 1);
      }
      return { hoverKeys: newHoverKeys };
    });
  };

  cardsSelectedFunc = (key) => {
    this.setState((prevState) => {
      const newSelectKeys = prevState.selectedKeys;
      if (newSelectKeys.includes(key)) {
        newSelectKeys.splice(newSelectKeys.indexOf(key), 1);
      } else {
        newSelectKeys.push(key);
      }
      return { selectedKeys: newSelectKeys, hoverKeys: [] };
    });
  };

  controlOptionFunc = () => {
    const { source } = this.props;

    const typeOpts = {
      group: SOURCE,
      items: [{
        value: SOURCE_TRADES, label: <FormattedMessage id="property.property_show.chart.control.source.trades.title" defaultMessage="Trades" />, active: (source === SOURCE_TRADES), disabled: false,
      }, {
        value: SOURCE_HISTORIAN, label: <FormattedMessage id="property.property_show.chart.control.source.historian.title" defaultMessage="Meter" />, active: (source === SOURCE_HISTORIAN), disabled: false,
      }],
    };
    return [typeOpts];
  };

  tooltipUpdate = (timestamp) => {
    this.setState({ tooltipTimestamp: timestamp });
  };

  handleChartClick = () => {
    const { historianAggregation, handleTimespanUpdate } = this.props;
    const { tooltipTimestamp } = this.state;

    if (historianAggregation.match(/^\d+S$/)) {
      return;
    }
    if (tooltipTimestamp === null) {
      return;
    }

    handleTimespanUpdate(tooltipTimestamp, tooltipTimestamp);
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const {
      historianAggregation, tradeAggregation,
      source, unit, controlSetStateFunc, chartView, mainData,
    } = this.props;
    const {
      tooltipTimestamp, hoverKeys, selectedKeys, chartSize,
    } = this.state;

    const { width } = chartSize;
    const { aggregateBy } = chartView || {};
    const isByMeter = aggregateBy === DATA_AGGREGATE_BY_METER;
    const metersLength = mainData?.meters?.length || 0;
    const multipleMeter = metersLength > 1;

    const meterView = source === SOURCE_HISTORIAN;
    const tradeView = source === SOURCE_TRADES;

    if (metersLength === 0) {
      return <Loading />;
    }

    const { hasCounterfactual } = mainData;
    const chartSummaryData = getChartSummaryData(mainData);
    const meterCardsData = buildMeterCardsData(mainData);
    const totalCarbonEmission = getTotalCarbonEmission(mainData?.buy?.data);
    const tradeCardsData = getTradeCardsData(mainData, tradeAggregation, chartView);
    const chartViewData = meterView ? buildChartMeterData(mainData, isByMeter)
      : buildChartTradeData(mainData, tradeAggregation, chartView, source);

    const timezone = mainData?.property?.timezone || TIME_ZONE_SYSTEM;

    const {
      ChartControls, ChartSummary, ChartMeter, ChartTrade,
    } = Chart;

    if (chartSummaryData.buy.untradedCount + chartSummaryData.sell.untradedCount > 0) {
      const untraded = {
        key: UNTRADED_ENERGY_KEY,
        label: UNTRADED_ENERGY_KEY,
        buy: {
          count: chartSummaryData.buy.untradedCount,
          value: hasCounterfactual ? chartSummaryData.buy.untradedValue : NaN,
          volume: chartSummaryData.buy.untradedVolume,

        },
        sell: {
          count: chartSummaryData.sell.untradedCount,
          value: hasCounterfactual ? chartSummaryData.sell.untradedValue : NaN,
          volume: chartSummaryData.sell.untradedVolume,

        },
      };

      tradeCardsData.push(untraded);
    }

    return (
      <>
        <div className="mt-4 mb-4 card">
          <div className="card-body" ref={this.chartRef}>
            <div className="d-flex justify-content-between">
              <div className="flex-fill justify-content-center order-2 order-xs-3 col-xs-12">
                <ChartControls
                  buttons={this.controlOptionFunc()}
                  onButtonClick={(opts) => { controlSetStateFunc(opts); }}
                />
              </div>
              <div className="flex-fill order-1 order-xs-1 col-xs-12">
                <ChartSummary
                  align="left"
                  category="imports"
                  hasCounterfactual={hasCounterfactual}
                  counterfactual={chartSummaryData.buy.netDiffValue}
                  tradedEnergy={chartSummaryData.buy.tradedEnergy}
                  tradedValue={chartSummaryData.buy.tradedValue}
                  untradedEnergy={chartSummaryData.buy.untradedVolume}
                  untradedValue={chartSummaryData.buy.untradedValue}
                  rebatedEnergy={chartSummaryData.buy.rebatedEnergy}
                  carbonData={totalCarbonEmission}
                  mode={APIConfig().MODE}
                />
              </div>
              <div className="flex-fill order-3 order-xs-2 col-xs-12">
                <ChartSummary
                  align="right"
                  category="exports"
                  hasCounterfactual={hasCounterfactual}
                  counterfactual={chartSummaryData.sell.netDiffValue}
                  tradedEnergy={chartSummaryData.sell.tradedEnergy}
                  tradedValue={chartSummaryData.sell.tradedValue}
                  untradedEnergy={chartSummaryData.sell.untradedVolume}
                  untradedValue={chartSummaryData.sell.untradedValue}
                  rebatedEnergy={chartSummaryData.sell.rebatedEnergy}
                  mode={APIConfig().MODE}
                />
              </div>
            </div>
            {meterView && (
              <ChartMeter
                aggregation={historianAggregation}
                chartView={chartView}
                data={chartViewData}
                handleChartClick={() => this.handleChartClick()}
                hoverKeys={hoverKeys}
                isByMeter={isByMeter}
                selectedKeys={selectedKeys}
                tooltipTimestamp={tooltipTimestamp}
                tooltipUpdate={(timestamp) => this.tooltipUpdate(timestamp)}
                unit={unit}
                width={width}
              />
            )}
            {tradeView && (
              <ChartTrade
                aggregation={tradeAggregation}
                chartView={chartView}
                handleChartClick={() => this.handleChartClick()}
                hoverKeys={hoverKeys}
                multipleMeter={multipleMeter}
                selectedKeys={selectedKeys}
                timezone={timezone}
                tooltipTimestamp={tooltipTimestamp}
                tooltipUpdate={(timestamp) => this.tooltipUpdate(timestamp)}
                tradeDataBuy={chartViewData.buy}
                tradeDataSell={chartViewData.sell}
                unit={unit}
                width={width}
              />
            )}
          </div>
        </div>
        {(meterView && isByMeter) && (
          <PropertyShowChartCards
            counterParties={meterCardsData}
            hoverFunc={this.cardsHoverFunc}
            hoverKeys={hoverKeys}
            selectedFunc={this.cardsSelectedFunc}
            selectedKeys={selectedKeys}
            chartView={chartView}
            meterCard
          />
        )}
        {tradeView && (
          <PropertyShowChartCards
            counterParties={tradeCardsData}
            hoverFunc={this.cardsHoverFunc}
            hoverKeys={hoverKeys}
            selectedFunc={this.cardsSelectedFunc}
            selectedKeys={selectedKeys}
            chartView={chartView}
          />
        )}
      </>
    );
  }
}

PropertyShowChart.propTypes = {
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_PROPERTY,
      DATA_AGGREGATE_BY_METER,
    ]).isRequired,
  }).isRequired,
  controlSetStateFunc: PropTypes.func.isRequired,
  handleTimespanUpdate: PropTypes.func.isRequired,
  historianAggregation: PropTypes.string.isRequired,
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  tradeAggregation: PropTypes.string.isRequired,
  unit: PropTypes.oneOf([UNIT_ENERGY, UNIT_CARBON, UNIT_CURRENCY]).isRequired,
  source: PropTypes.oneOf([SOURCE_HISTORIAN, SOURCE_TRADES]).isRequired,
};

export default injectIntl(PropertyShowChart);
