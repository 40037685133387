import { DateTime } from 'luxon';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Description
 * @param {object} props - The component props.
 * @param {number} props.unixTimestamp - The Unix timestamp.
 * @param {string|React.ReactElement} props.child - The child that the time element
 * will wrap around.
 * @returns {React.ReactComponentElement} - Time component
 */
function Time({ unixTimestamp, child }) {
  if (!child || !unixTimestamp) return null;

  const isoValue = DateTime.fromSeconds(unixTimestamp).toUTC().toISO();

  return (
    <time dateTime={isoValue}>{child}</time>
  );
}

Time.propTypes = {
  unixTimestamp: PropTypes.number.isRequired,
  child: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default Time;
