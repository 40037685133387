import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import Footer from 'src/enosikit/components/Footer';
import Flashes from 'src/components/Flashes';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - HTTP404 component
 */
function HTTP404(props) {
  const { children, intl } = props;

  const pageTitle = intl.formatMessage({ id: 'http404.page_title', defaultMessage: 'Enosi - Login to get started' });
  const header = intl.formatMessage({ id: 'http404.header.text', defaultMessage: 'Not found' });
  const content = intl.formatMessage({ id: 'http404.content.text', defaultMessage: 'We are not sure where we are...' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <AnonymousNavigation />
        <Flashes />
        <div className="flex-auto">
          <div className="container text-center">
            <h1>{header}</h1>
            <p>{content}</p>
            {children}
          </div>
        </div>
        <Footer versionUI={VERSION} />
      </div>
    </>
  );
}

HTTP404.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

HTTP404.defaultProps = {
  children: null,
};

export default injectIntl(HTTP404);
