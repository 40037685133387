import { Duration } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { timeOfDayToDuration } from 'src/util/timeOfUse';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - TimePicker component
 */
function TimePicker(props) {
  const {
    value, onChange, min, max, step,
  } = props;

  const minDuration = timeOfDayToDuration(min);
  const maxDuration = timeOfDayToDuration(max);
  const stepDuration = Duration.fromObject({ minutes: step });
  const options = [];
  for (let option = minDuration; option <= maxDuration; option = option.plus(stepDuration)) {
    options.push(option.toFormat('hh:mm'));
  }

  let valueString = '';
  if (value) {
    const valueDuration = timeOfDayToDuration(value);
    valueString = valueDuration.toFormat('hh:mm');
  }

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    if (newValue === '') {
      onChange(null);
      return;
    }
    const [hours, minutes] = newValue.split(':').map(Number);
    onChange({ hours, minutes });
  };

  return (
    <Input type="select" value={valueString} onChange={handleChange}>
      { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <option value="">
        <FormattedMessage id="time_of_use.time_picker.form.select.title" defaultMessage="Select time" />
      </option>
      {options.map((option) => (
        <option key={option}>{option}</option>
      ))}
    </Input>
  );
}

const timeOfDayShape = PropTypes.shape({
  hours: PropTypes.number,
  minutes: PropTypes.number,
  // seconds: PropTypes.number,
  // nanos: PropTypes.number,
});

TimePicker.propTypes = {
  value: timeOfDayShape,
  onChange: PropTypes.func,
  min: timeOfDayShape,
  max: timeOfDayShape,
  step: PropTypes.number,
};

TimePicker.defaultProps = {
  value: null,
  onChange: () => { },
  min: { hours: 0, minutes: 0 },
  max: { hours: 24, minutes: 0 },
  step: 30,
};

export default TimePicker;
