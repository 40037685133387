import React from 'react';

import FlashesStore from 'src/stores/FlashesStore';
// import PusherStore from 'src/stores/PusherStore';
// import CentrifugeStore from 'src/stores/CentrifugeStore';

import { FormattedMessage } from 'react-intl';
import Flash from './Flash';

const FLASH_CONN_ERROR_ID = 'FLASH_CONN_ERROR';

/**
 *
 * @param {Array<object>} children - element to be translated
 * @returns {React.ReactElement} - part of the translated string
 */
function SpanComponent(children) {
  return <span className="fw-semibold">{children}</span>;
}
class Flashes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flashes: [],
      lastConnected: true, // assume we were connected when the page loaded
    };
  }

  UNSAFE_componentWillMount() {
    if (FlashesStore.preloaded) {
      this.setState({ flashes: FlashesStore.preloaded });
    }
  }

  componentDidMount() {
    FlashesStore.on('flash', this.handleStoreChange);
    FlashesStore.on('reset', this.handleStoreReset);
    // PusherStore.on('unavailable', this.handleConnectionError);
    // PusherStore.on('connected', this.handleConnectionSuccess);
    // CentrifugeStore.on('disconnect', this.handleConnectionError);
    // CentrifugeStore.on('connect', this.handleConnectionSuccess);
  }

  componentWillUnmount() {
    FlashesStore.off('flash', this.handleStoreChange);
    FlashesStore.off('reset', this.handleStoreReset);
    // PusherStore.off('unavailable', this.handleConnectionError);
    // PusherStore.off('connected', this.handleConnectionSuccess);
    // CentrifugeStore.off('disconnect', this.handleConnectionError);
    // CentrifugeStore.off('connect', this.handleConnectionSuccess);
  }

  handleStoreChange = (payload) => {
    this.setState((prevState) => {
      const { flashes } = prevState;
      return { flashes: flashes.concat(payload) };
    });
  };

  handleStoreReset = () => {
    this.setState((prevState) => {
      const flashes = this.hasConnectionErrorFlash() ? prevState.flashes.slice(0, 1) : [];

      return { flashes };
    });
  };

  // show a flash when there's a push connection issue
  //
  // NOTE: Pusher only sends this event if the following conditions are met;
  //        1. The websocket timed out (30 seconds by default)
  //        2. Reconnecting the websocket (after 10 seconds) failed
  //       Thus, this can only happen once in a given 40 second period.
  // TODO: verify use.
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleConnectionError = () => {
    const { lastConnected } = this.state;

    if (this.hasConnectionErrorFlash() || !lastConnected) {
      // try not to be irritating; don't add a new flash when
      // we're showing one, or when we haven't reconnected yet.
      //
      // this means we don't add a new flash if the user
      // dismissed one before a connection was restored
      // (kinder for those with flaky connections! <3)
      return;
    }

    const connectionFlash = {
      id: FLASH_CONN_ERROR_ID,
      type: FlashesStore.ERROR,
      message: (
        <span>
          <FormattedMessage
            id="flashes.connection_error.flash.message"
            defaultMessage="<sb>Couldn’t connect to Enosi for push updates.</sb>  We’ll try to reconnect! Information won’t update automatically for now."
            values={{ sb: (children) => SpanComponent(children) }}
          />
        </span>
      ),
    };

    // prepend the flash
    this.setState((prevState) => {
      const { flashes } = prevState;
      return { flashes: [connectionFlash].concat(flashes), lastConnected: false };
    });
  };

  // hide connection error flash (if it exists!) when reconnected
  // TODO: verify use.
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleConnectionSuccess = () => {
    const newState = {
      // make it known that we got a good connection!
      lastConnected: true,
    };

    // as the flash is always prepended (and no other flash is),
    // we can slice from the front rather than filter or find index
    if (this.hasConnectionErrorFlash()) {
      const { flashes } = this.state;
      newState.flashes = flashes.slice(1);
    }

    this.setState(newState);
  };

  handleFlashRemove = (flash) => {
    this.setState((prevState) => {
      const { flashes } = prevState;
      return {
        flashes: flashes.filter((nextFlash) => flash.id !== nextFlash.id),
      };
    });
  };

  hasConnectionErrorFlash() {
    const { flashes } = this.state;
    return flashes.length > 0 && flashes[0].id === FLASH_CONN_ERROR_ID;
  }

  render() {
    const { flashes } = this.state;

    if (flashes.length > 0) {
      return (
        <div className="container mb4">
          {flashes.map((flash) => (
            <Flash
              key={flash.id}
              flash={flash}
              onRemoveClick={this.handleFlashRemove}
            />
          ))}
        </div>
      );
    }

    return null;
  }
}

Flashes.propTypes = {};

export default Flashes;
