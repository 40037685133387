/* eslint-disable camelcase */

// Day
export const DAY_MONDAY = 'MONDAY';
export const DAY_TUESDAY = 'TUESDAY';
export const DAY_WEDNESDAY = 'WEDNESDAY';
export const DAY_THURSDAY = 'THURSDAY';
export const DAY_FRIDAY = 'FRIDAY';
export const DAY_SATURDAY = 'SATURDAY';
export const DAY_SUNDAY = 'SUNDAY';
export const DAY_PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY';

export const getDayNames = (formatMessage) => {
  const DayNames = {
    [DAY_MONDAY]: formatMessage({ id: 'day_name_monday', defaultMessage: 'Monday' }),
    [DAY_TUESDAY]: formatMessage({ id: 'day_name_tuesday', defaultMessage: 'Tuesday' }),
    [DAY_WEDNESDAY]: formatMessage({ id: 'day_name_wednesday', defaultMessage: 'Wednesday' }),
    [DAY_THURSDAY]: formatMessage({ id: 'day_name_thursday', defaultMessage: 'Thursday' }),
    [DAY_FRIDAY]: formatMessage({ id: 'day_name_friday', defaultMessage: 'Friday' }),
    [DAY_SATURDAY]: formatMessage({ id: 'day_name_saturday', defaultMessage: 'Saturday' }),
    [DAY_SUNDAY]: formatMessage({ id: 'day_name_sunday', defaultMessage: 'Sunday' }),
    [DAY_PUBLIC_HOLIDAY]: formatMessage({ id: 'day_name_publicholiday', defaultMessage: 'Public Holiday' }),
    // [DAY_PUBLIC_HOLIDAY]: 'Public Holiday',
  };
  return DayNames;
};

// Month
export const MONTH_JANUARY = 'JANUARY';
export const MONTH_FEBRUARY = 'FEBRUARY';
export const MONTH_MARCH = 'MARCH';
export const MONTH_APRIL = 'APRIL';
export const MONTH_MAY = 'MAY';
export const MONTH_JUNE = 'JUNE';
export const MONTH_JULY = 'JULY';
export const MONTH_AUGUST = 'AUGUST';
export const MONTH_SEPTEMBER = 'SEPTEMBER';
export const MONTH_OCTOBER = 'OCTOBER';
export const MONTH_NOVEMBER = 'NOVEMBER';
export const MONTH_DECEMBER = 'DECEMBER';

export const getMonthNames = (formatMessage) => {
  const MonthNames = {
    [MONTH_JANUARY]: formatMessage({ id: 'month_name_january', defaultMessage: 'January' }),
    [MONTH_FEBRUARY]: formatMessage({ id: 'month_name_february', defaultMessage: 'February' }),
    [MONTH_MARCH]: formatMessage({ id: 'month_name_march', defaultMessage: 'March' }),
    [MONTH_APRIL]: formatMessage({ id: 'month_name_april', defaultMessage: 'April' }),
    [MONTH_MAY]: formatMessage({ id: 'month_name_may', defaultMessage: 'May' }),
    [MONTH_JUNE]: formatMessage({ id: 'month_name_june', defaultMessage: 'June' }),
    [MONTH_JULY]: formatMessage({ id: 'month_name_july', defaultMessage: 'July' }),
    [MONTH_AUGUST]: formatMessage({ id: 'month_name_august', defaultMessage: 'August' }),
    [MONTH_SEPTEMBER]: formatMessage({ id: 'month_name_september', defaultMessage: 'September' }),
    [MONTH_OCTOBER]: formatMessage({ id: 'month_name_october', defaultMessage: 'October' }),
    [MONTH_NOVEMBER]: formatMessage({ id: 'month_name_november', defaultMessage: 'November' }),
    [MONTH_DECEMBER]: formatMessage({ id: 'month_name_december', defaultMessage: 'December' }),
  };
  return MonthNames;
};

export const getChartLocale = (formatMessage) => {
  const locale = {
    currency: [formatMessage({ id: 'common.entities.currency.label', defaultMessage: '$' }), ''],
    periods: [formatMessage({ id: 'locale_am', defaultMessage: 'AM' }), formatMessage({ id: 'locale_pm', defaultMessage: 'PM' })],
    // Weekdays should be in the order of Sun - Sat Ref: https://d3js.org/d3-time-format#timeFormatLocale
    days: [
      formatMessage({ id: 'day_name_sunday', defaultMessage: 'Sunday' }),
      formatMessage({ id: 'day_name_monday', defaultMessage: 'Monday' }),
      formatMessage({ id: 'day_name_tuesday', defaultMessage: 'Tuesday' }),
      formatMessage({ id: 'day_name_wednesday', defaultMessage: 'Wednesday' }),
      formatMessage({ id: 'day_name_thursday', defaultMessage: 'Thursday' }),
      formatMessage({ id: 'day_name_friday', defaultMessage: 'Friday' }),
      formatMessage({ id: 'day_name_saturday', defaultMessage: 'Saturday' })],
    // Weekdays should be in the order of Sun - Sat Ref: https://d3js.org/d3-time-format#timeFormatLocale
    shortDays: [
      formatMessage({ id: 'day_name_short_sunday', defaultMessage: 'Sun' }),
      formatMessage({ id: 'day_name_short_monday', defaultMessage: 'Mon' }),
      formatMessage({ id: 'day_name_short_tuesday', defaultMessage: 'Tue' }),
      formatMessage({ id: 'day_name_short_wednesday', defaultMessage: 'Wed' }),
      formatMessage({ id: 'day_name_short_thursday', defaultMessage: 'Thu' }),
      formatMessage({ id: 'day_name_short_friday', defaultMessage: 'Fri' }),
      formatMessage({ id: 'day_name_short_saturday', defaultMessage: 'Sat' }),
    ],
    months: [
      formatMessage({ id: 'month_name_january', defaultMessage: 'January' }),
      formatMessage({ id: 'month_name_february', defaultMessage: 'February' }),
      formatMessage({ id: 'month_name_march', defaultMessage: 'March' }),
      formatMessage({ id: 'month_name_april', defaultMessage: 'April' }),
      formatMessage({ id: 'month_name_may', defaultMessage: 'May' }),
      formatMessage({ id: 'month_name_june', defaultMessage: 'June' }),
      formatMessage({ id: 'month_name_july', defaultMessage: 'July' }),
      formatMessage({ id: 'month_name_august', defaultMessage: 'August' }),
      formatMessage({ id: 'month_name_september', defaultMessage: 'September' }),
      formatMessage({ id: 'month_name_october', defaultMessage: 'October' }),
      formatMessage({ id: 'month_name_november', defaultMessage: 'November' }),
      formatMessage({ id: 'month_name_december', defaultMessage: 'December' }),
    ],
    shortMonths: [
      formatMessage({ id: 'month_name_short_january', defaultMessage: 'Jan' }),
      formatMessage({ id: 'month_name_short_february', defaultMessage: 'Feb' }),
      formatMessage({ id: 'month_name_short_march', defaultMessage: 'Mar' }),
      formatMessage({ id: 'month_name_short_april', defaultMessage: 'Apr' }),
      formatMessage({ id: 'month_name_short_may', defaultMessage: 'May' }),
      formatMessage({ id: 'month_name_short_june', defaultMessage: 'Jun' }),
      formatMessage({ id: 'month_name_short_july', defaultMessage: 'Jul' }),
      formatMessage({ id: 'month_name_short_august', defaultMessage: 'Aug' }),
      formatMessage({ id: 'month_name_short_september', defaultMessage: 'Sep' }),
      formatMessage({ id: 'month_name_short_october', defaultMessage: 'Oct' }),
      formatMessage({ id: 'month_name_short_november', defaultMessage: 'Nov' }),
      formatMessage({ id: 'month_name_short_december', defaultMessage: 'Dec' }),
    ],
  };
  return locale;
};

export const decimalSeparator = {
  it: ',',
};
