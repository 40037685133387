import React from 'react';

import FlashesStore from 'src/stores/FlashesStore';

/**
 * @constant
 * @type {number}
 * @default
 */
export const WARNING_TIMEOUT = 5000;

/**
 * Creates the warning dialog when the user in dashboard trades view
 * clicks on trades toggle button more than once in 5s
 * @param {React.ReactComponentElement} warningDialog - i18n
 * @returns {void}
 */
export const showTradeRuleMsg = (warningDialog) => {
  clearTimeout(undefined);

  const tradeRulesNav = document.querySelectorAll('nav.navbar .nav-item.nav-trade-rules');

  if (tradeRulesNav && tradeRulesNav[0]) {
    FlashesStore.flash(
      FlashesStore.WARNING,
      warningDialog,
    );
    tradeRulesNav[0].classList?.add('trade-rules-highlight');
    tradeRulesNav[0].focus();
    setTimeout(() => {
      tradeRulesNav[0].classList?.remove('trade-rules-highlight');
      FlashesStore.reset();
    }, WARNING_TIMEOUT);
  }
};
