import Big from 'big.js';
import { DateTime } from 'luxon';

/**
 * Emissions factors, for each year type, in g CO2•e/Wh.
 */
export const EMISSION_FACTORS = {
  cal2016: {
    timeRange: {
      start: DateTime.fromISO('2016-01-01T00:00:00+08:00'),
      finish: DateTime.fromISO('2017-01-01T00:00:00+08:00'),
    },
    regions: {
      SG: Big(0.4237),
    },
  },
  cal2017: {
    timeRange: {
      start: DateTime.fromISO('2017-01-01T00:00:00+08:00'),
      finish: DateTime.fromISO('2018-01-01T00:00:00+08:00'),
    },
    regions: {
      SG: Big(0.421),
    },
  },
  cal2018: {
    timeRange: {
      start: DateTime.fromISO('2018-01-01T00:00:00+08:00'),
      finish: DateTime.fromISO('2019-01-01T00:00:00+08:00'),
    },
    regions: {
      SG: Big(0.4206),
    },
  },
  cal2019: {
    timeRange: {
      start: DateTime.fromISO('2019-01-01T00:00:00+08:00'),
      finish: DateTime.fromISO('2020-01-01T00:00:00+08:00'),
    },
    regions: {
      SG: Big(0.4085),
    },
  },
  cal2020: {
    timeRange: {
      start: DateTime.fromISO('2020-01-01T00:00:00+08:00'),
      finish: DateTime.fromISO('2021-01-01T00:00:00+08:00'),
    },
    regions: {
      SG: Big(0.4074),
    },
  },
  cal2021: {
    timeRange: {
      start: DateTime.fromISO('2022-01-01T00:00:00+08:00'),
      finish: null,
    },
    regions: {
      SG: Big(0.4057),
    },
  },
  fy13: {
    timeRange: {
      start: DateTime.fromISO('2012-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2013-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.86),
      'AU-ACT': Big(0.86),
      'AU-NT': Big(0.67),
      'AU-QLD': Big(0.80),
      'AU-SA': Big(0.58),
      'AU-TAS': Big(0.19),
      'AU-VIC': Big(1.16),
      'AU-WA': Big(0.76),
      AU: Big(0.85),
    },
  },
  fy14: {
    timeRange: {
      start: DateTime.fromISO('2013-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2014-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.84),
      'AU-ACT': Big(0.84),
      'AU-NT': Big(0.67),
      'AU-QLD': Big(0.79),
      'AU-SA': Big(0.55),
      'AU-TAS': Big(0.14),
      'AU-VIC': Big(1.11),
      'AU-WA': Big(0.74),
      AU: Big(0.82),
    },
  },
  fy15: {
    timeRange: {
      start: DateTime.fromISO('2014-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2015-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.83),
      'AU-ACT': Big(0.83),
      'AU-NT': Big(0.66),
      'AU-QLD': Big(0.78),
      'AU-SA': Big(0.53),
      'AU-TAS': Big(0.15),
      'AU-VIC': Big(1.07),
      'AU-WA': Big(0.71),
      AU: Big(0.81),
    },
  },
  fy16: {
    timeRange: {
      start: DateTime.fromISO('2015-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2016-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.83),
      'AU-ACT': Big(0.83),
      'AU-NT': Big(0.65),
      'AU-QLD': Big(0.79),
      'AU-SA': Big(0.53),
      'AU-TAS': Big(0.19),
      'AU-VIC': Big(1.07),
      'AU-WA': Big(0.70),
      AU: Big(0.80),
    },
  },
  fy17: {
    timeRange: {
      start: DateTime.fromISO('2016-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2017-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.82),
      'AU-ACT': Big(0.82),
      'AU-NT': Big(0.64),
      'AU-QLD': Big(0.80),
      'AU-SA': Big(0.51),
      'AU-TAS': Big(0.23),
      'AU-VIC': Big(1.07),
      'AU-WA': Big(0.70),
      AU: Big(0.80),
    },
  },
  fy18: {
    timeRange: {
      start: DateTime.fromISO('2017-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2018-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.81),
      'AU-ACT': Big(0.81),
      'AU-NT': Big(0.63),
      'AU-QLD': Big(0.81),
      'AU-SA': Big(0.44),
      'AU-TAS': Big(0.19),
      'AU-VIC': Big(1.02),
      'AU-WA': Big(0.69),
      AU: Big(0.79),
    },
  },
  fy19: {
    timeRange: {
      start: DateTime.fromISO('2018-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2019-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.79),
      'AU-ACT': Big(0.79),
      'AU-NT': Big(0.57),
      'AU-QLD': Big(0.80),
      'AU-SA': Big(0.35),
      'AU-TAS': Big(0.16),
      'AU-VIC': Big(0.96),
      'AU-WA': Big(0.68),
      AU: Big(0.76),
    },
  },
  fy20: {
    timeRange: {
      start: DateTime.fromISO('2019-07-01T00:00:00+10:00'),
      finish: DateTime.fromISO('2020-07-01T00:00:00+10:00'),
    },
    regions: {
      'AU-NSW': Big(0.78),
      'AU-ACT': Big(0.78),
      'AU-NT': Big(0.54),
      'AU-QLD': Big(0.80),
      'AU-SA': Big(0.30),
      'AU-TAS': Big(0.14),
      'AU-VIC': Big(0.91),
      'AU-WA': Big(0.67),
      AU: Big(0.73),
    },
  },
  fy21: {
    timeRange: {
      start: DateTime.fromISO('2020-07-01T00:00:00+10:00'),
      finish: null,
    },
    regions: {
      'AU-NSW': Big(0.73),
      'AU-ACT': Big(0.73),
      'AU-NT': Big(0.54),
      'AU-QLD': Big(0.73),
      'AU-SA': Big(0.25),
      'AU-TAS': Big(0.17),
      'AU-VIC': Big(0.85),
      'AU-WA': Big(0.545),
      AU: Big(0.68),
    },
  },
};

/**
 *  ISO based country codes.
 */
export const COUNTRY_CODES = {
  default: 'AU',
  australia: 'AU',
  singapore: 'SG',
};

/**
 * Default carbon free emission factor to be applied to non carbon values.
 */
export const DEFAULT_CARBON_FREE_EMISSION_FACTOR = Big(0);

/**
 * The constants below are also present in `src/util/constants`. These are duplicated
 * here because we want `lib/carbondata` to be independent of any external dependencies.
 */

/**
 * Trade direction is "buy".
 */
export const TRADE_DIRECTION_BUY = 'TRADE_DIRECTION_BUY';

/**
 * Trade direction is "sell".
 */
export const TRADE_DIRECTION_SELL = 'TRADE_DIRECTION_SELL';

/**
 * Trade type is "residual" (also referred to as "retailer default").
 */
export const TRADE_TYPE_RESIDUAL = 'TRADE_TYPE_RESIDUAL';

/**
 * Trade label.
 */
export const TRADE = 'TRADE';

/**
 * Meter label.
 */
export const METER = 'METER';

export const UNIT_ENERGY_ACRONYM = 'Wh';

export const UNIT_CARBON_EMISSIONS_ACRONYM = 'g-CO2•e';
