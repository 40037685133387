/* eslint-disable import/no-dynamic-require */
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import {
  Button, ButtonGroup, ButtonToolbar,
  Dropdown, DropdownMenu, DropdownItem,
  DropdownToggle, Label, Input,
} from 'reactstrap';
import Chart from 'src/enosikit/components/Chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartColumn,
  faDatabase,
  faDownload,
  faTableCells,
} from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { APIConfig } from 'src/config';

import {
  DATA_AGGREGATE_BY_PROPERTY, DATA_AGGREGATE_BY_METER,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
  DATA_PACK, DIRECTIONS, DOWNLOAD_SELECTOR, INHIBIT_CARBON_DATA_VIEWS,
  METER, MIME_TYPE_CSV, MIME_TYPE_JSON, SOURCE_HISTORIAN, SOURCE_TRADES,
  TIME_ZONE_SYSTEM, TRADE, VIEW_SELECTOR,
} from 'src/util/constants';
import { getLocale } from 'src/util/i18n/handler';
import csvFileHeaders from 'src/util/helpers';
import { dateToTimeInTimezone } from 'src/util/time';
import {
  downloadHandler, processCSVData, prepareCSVData, processDataPack,
} from './helpers/propertyShowControl';

const BtnGroupWrapper = styled(ButtonGroup)`
  {
    z-index: 9999;
  }
`;

const ViewWrapper = styled(DropdownToggle)`
{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
`;

const DownloadWrapper = styled(DropdownToggle)`
{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
`;

/**
 * Initiate csv or json data download
 * Data schema for meter - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743569/CSV+meter+data+download
 * Data schema for trade - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838710828/CSV+trade+history+data+download
 * Data schema for data pack - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743595/JSON+property+data+download
 * @param {string} type - meter, trade or data pack
 * @param {object} mainData
 * @param {object} dateRange - {start, finish}
 * @param {DateTime} [dateRange.start]
 * @param {DateTime} [dateRange.finish]
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 */
const downloadManager = (type, mainData, dateRange, intl) => {
  if (!mainData) {
    return;
  }
  if (type === DATA_PACK) {
    const { data: jsonData, fileName } = processDataPack(mainData, dateRange, intl);
    if (jsonData && JSON.stringify(jsonData)) {
      downloadHandler(JSON.stringify(jsonData), fileName, MIME_TYPE_JSON);
    }
    return;
  }

  const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);
  const { data: finalCSVData, fileName } = processCSVData(
    type,
    mainData,
    dateRange,
    intl,
  ) || {};

  const headers = csvFileHeaders(intl, type, inhibitCarbonDataViews);

  if (finalCSVData) {
    const csvdata = prepareCSVData(finalCSVData, headers);
    if (csvdata) {
      const finalFileName = `${fileName}.csv`;
      downloadHandler(csvdata, finalFileName, MIME_TYPE_CSV);
    }
  }
};

/**
 * Chart view selector dropdown
 * @param {boolean} dropdownOpen - flag to decide the status(close/open) of the dropdown
 * @param {Function} toggle
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @param {Function} setChartView
 * @param {SOURCE_HISTORIAN | SOURCE_TRADES} source
 * @returns {React.ReactElement} - chart view dropdown.
 */
export const chartViewSelector = (dropdownOpen, toggle, chartView, setChartView, source) => {
  const { aggregateBy, groupBy } = chartView;
  const aggregateByProperty = aggregateBy === DATA_AGGREGATE_BY_PROPERTY;
  const aggregateByMeter = aggregateBy === DATA_AGGREGATE_BY_METER;
  const groupByCounterparty = groupBy === DATA_GROUP_BY_COUNTERPARTY;
  const groupByTradeType = groupBy === DATA_GROUP_BY_TRADE_TYPE;
  return (
    <Dropdown className="chart-view-selector" isOpen={dropdownOpen} toggle={() => toggle(VIEW_SELECTOR)}>
      <ViewWrapper className="btn btn-darken" caret>
        <FontAwesomeIcon icon={faChartColumn} size="1x" />
      </ViewWrapper>
      <DropdownMenu end>
        <DropdownItem header><FormattedMessage id="property.property_show.control.chart_selector.group_by.title" defaultMessage="Group by" /></DropdownItem>
        <DropdownItem
          disabled={source === SOURCE_HISTORIAN}
          onClick={() => setChartView(
            {
              aggregateBy,
              groupBy: DATA_GROUP_BY_COUNTERPARTY,
            },
          )}
        >
          <Label>
            <Input className="me-1" defaultChecked={groupByCounterparty} type="radio" name="chart_group_by" />
            <FormattedMessage id="property.property_show.control.chart_selector.group_by.option.counterparty" defaultMessage="Counterparty" />
          </Label>
        </DropdownItem>
        <DropdownItem
          disabled={source === SOURCE_HISTORIAN}
          onClick={() => setChartView(
            {
              aggregateBy,
              groupBy: DATA_GROUP_BY_TRADE_TYPE,
            },
          )}
        >
          <Label>
            <Input className="me-1" type="radio" defaultChecked={groupByTradeType} name="chart_group_by" />
            <FormattedMessage id="property.property_show.control.chart_selector.group_by.option.trade_type" defaultMessage="Trade type" />
          </Label>
        </DropdownItem>
        <DropdownItem header><FormattedMessage id="property.property_show.control.chart_selector.aggregate_by.title" defaultMessage="Aggregate by" /></DropdownItem>
        <DropdownItem onClick={() => setChartView({
          aggregateBy: DATA_AGGREGATE_BY_PROPERTY,
          groupBy,
        })}
        >
          <Label>
            <Input className="me-1" defaultChecked={aggregateByProperty} type="radio" name="chartview" />
            <FormattedMessage id="property.property_show.control.chart_selector.aggregate_by.option.property" defaultMessage="Property" />
          </Label>
        </DropdownItem>
        <DropdownItem onClick={() => setChartView({
          aggregateBy: DATA_AGGREGATE_BY_METER,
          groupBy,
        })}
        >
          <Label>
            <Input className="me-1" type="radio" defaultChecked={aggregateByMeter} name="chartview" />
            <FormattedMessage id="property.property_show.control.chart_selector.aggregate_by.option.meter" defaultMessage="Meter" />
          </Label>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

/**
 * File download dropdown
 * @param {object} mainData
 * @param {boolean} dropdownOpen - flag to decide the status(close/open) of the dropdown
 * @param {Function} toggle
 * @param {object} dateRange - {start, finish}
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {React.ReactElement} - file download dropdown.
 */
export const fileDownloadSelector = (
  mainData,
  dropdownOpen,
  toggle,
  dateRange,
  intl,
) => {
  const meterData = [];
  const tradeData = [];
  DIRECTIONS.forEach((dir) => {
    if (!mainData[dir].data) return;
    Object.keys(mainData[dir].data)?.forEach((timestamp) => {
      const { meterDataAggregates, tradeSetSummaries } = mainData[dir].data[timestamp] || {};
      if (meterDataAggregates) {
        Object.keys(meterDataAggregates).forEach(
          (meterId) => meterData.push(meterDataAggregates[meterId]),
        );
      }
      if (tradeSetSummaries) {
        Object.keys(tradeSetSummaries).forEach(
          (tradeRuleId) => tradeData.push(tradeSetSummaries[tradeRuleId]),
        );
      }
    });
  });

  let meterDataCSVDisabled = false;
  let tradeDataCSVDisabled = false;
  let dataPackJSONDisabled = false;

  if (meterData.length === 0 && tradeData.length === 0) {
    meterDataCSVDisabled = true;
    tradeDataCSVDisabled = true;
    dataPackJSONDisabled = true;
  } else {
    meterDataCSVDisabled = meterData.length === 0;

    tradeDataCSVDisabled = tradeData.length === 0;
  }

  return (
    <Dropdown className="download-type-selector" isOpen={dropdownOpen} toggle={() => toggle(DOWNLOAD_SELECTOR)}>
      <DownloadWrapper className="btn btn-darken" caret><FontAwesomeIcon icon={faDownload} size="1x" className="me-2" /></DownloadWrapper>
      <DropdownMenu end>
        <DropdownItem
          disabled={meterDataCSVDisabled}
          className={`${meterDataCSVDisabled ? 'pe-none' : 'pe-auto'}`}
          onClick={() => downloadManager(METER, mainData, dateRange, intl)}
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-disabled={`${!!meterDataCSVDisabled}`}
          data-testid="meter-data-csv"
        >
          <Label>
            <FontAwesomeIcon icon={faTableCells} size="1x" className="me-2" />
            <FormattedMessage id="property.property_show.control.download.csv.meter_data" defaultMessage="Meter data (.csv)" />
          </Label>
        </DropdownItem>
        <DropdownItem
          disabled={tradeDataCSVDisabled}
          className={`${tradeDataCSVDisabled ? 'pe-none' : 'pe-auto'}`}
          onClick={() => downloadManager(TRADE, mainData, dateRange, intl)}
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-disabled={`${!!tradeDataCSVDisabled}`}
          data-testid="trade-data-csv"
        >
          <Label>
            <FontAwesomeIcon icon={faTableCells} size="1x" className="me-2" />
            <FormattedMessage id="property.property_show.control.download.csv.trade_data" defaultMessage="Trade data (.csv)" />
          </Label>
        </DropdownItem>
        <DropdownItem
          disabled={dataPackJSONDisabled}
          className={`${dataPackJSONDisabled ? 'pe-none' : 'pe-auto'}`}
          onClick={() => downloadManager(
            DATA_PACK,
            mainData,
            dateRange,
            intl,
          )}
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-disabled={`${!!dataPackJSONDisabled}`}
          data-testid="data-pack-json"
        >
          <Label>
            <FontAwesomeIcon icon={faDatabase} size="1x" className="me-2" />
            <FormattedMessage id="property.property_show.control.download.json" defaultMessage="Data pack (.json)" />
          </Label>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

/**
 * Wrap a DatePicker to support a custom timezone.
 * @param {object} props
 * @param {string} props.timezone
 * @param {DateTime} props.start
 * @param {DateTime} props.finish
 * @param {Function} props.ref
 * @param {Function} props.timeSpanButtonLabel
 * @param {number} props.maxNumberDays
 * @param {Function} props.onChange
 * @param {Function} props.onCalendarClose
 * @param {Function} props.onCalendarOpen
 * @param {Function} props.customCalendarClose
 * @returns {DatePickerInTimezone} -  React date picker component wrapped in a time zone(preferred)
 */
function DatePickerInTimezone({
  timezone, // Preferred timezone for start/finish. May be different to local timezone.
  start, // Custom timezone.
  finish, // Custom timezone.
  ref,
  timeSpanButtonLabel,
  maxNumberDays, // Maximum number of days selected
  onChange,
  onCalendarClose,
  onCalendarOpen,
  customCalendarClose,
}) {
  const dpStart = start ? DateTime.local(start.year, start.month, start.day).toJSDate() : null;
  const dpFinish = finish ? DateTime.local(finish.year, finish.month, finish.day).toJSDate() : null;
  const dpMaxDate = dpStart && !dpFinish ? DateTime.fromJSDate(dpStart)
    .plus({ days: maxNumberDays }).toJSDate() : null;

  const wrappedOnChange = (dates) => {
    const [localStartJS, localFinishJS] = dates; // Local time.
    const localStart = localStartJS
      ? DateTime.fromJSDate(localStartJS) : null; // Local datetime.
    const localFinish = localFinishJS
      ? DateTime.fromJSDate(localFinishJS) : null; // Local datetime.

    const preferredStart = localStart ? dateToTimeInTimezone(localStart, timezone) : null;
    const preferredFinish = localFinish ? dateToTimeInTimezone(localFinish, timezone) : null;

    onChange([preferredStart, preferredFinish]);
  };

  return (
    <DatePicker
      locale={getLocale()}
      ref={(r) => { ref(r); }}
      value={timeSpanButtonLabel(start, finish)}
      selected={dpStart && !dpFinish ? dpStart : null}
      startDate={dpStart || null}
      endDate={dpFinish || null}
      maxDate={dpMaxDate}
      onChange={wrappedOnChange}
      onCalendarClose={onCalendarClose}
      onCalendarOpen={onCalendarOpen}
      customInput={<TimeSpanButton />}
      monthsShown={2}
      selectsRange
      shouldCloseOnSelect={false}
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
    >
      <div
        style={{
          clear: 'both',
          textAlign: 'right',
          borderTop: '1px solid #ccc',
          padding: '1em',
        }}
      >
        <button className="btn btn-primary" type="button" onClick={customCalendarClose}>
          <FormattedMessage id="common.daterange_picker.form.submit" defaultMessage="Apply" />
        </button>
      </div>
    </DatePicker>
  );
}

/**
 *
 * @param {any} root0
 * @param {any} root0.mainData
 * @param {any} root0.timespan
 * @param {any} root0.timespanUpdateFunc
 * @param {any} root0.controlOptionFunc
 * @param {any} root0.controlSetStateFunc
 * @param {any} root0.viewSelectorOpen
 * @param {any} root0.setChartView
 * @param {any} root0.chartView
 * @param {any} root0.downloadSelectorOpen
 * @param {any} root0.toggle
 * @param {any} root0.source
 * @returns {React.ReactComponentElement} - PropertyShowControl component
 */
function PropertyShowControl({
  mainData, timespan, timespanUpdateFunc,
  controlOptionFunc, controlSetStateFunc,
  viewSelectorOpen, setChartView, chartView,
  downloadSelectorOpen, toggle, source,
}) {
  const [start, setStart] = useState(timespan.start);
  const [finish, setFinish] = useState(timespan.finish);
  const [myRef, setMyRef] = useState(false);
  const [apply, setApply] = useState(false);

  const timezone = mainData?.property?.timezone || TIME_ZONE_SYSTEM;

  useEffect(() => {
    if (timespan) {
      const { start: updatedStart, finish: updatedFinish } = timespan;
      setStart(updatedStart);
      setFinish(updatedFinish);
    }
  }, [timespan]);

  /**
   * Called when the date range picker's calendar is opened.
   * @returns {void}
   */
  const openCalendar = () => {
    const { start: prevStart, finish: prevFinish } = timespan;
    setStart(prevStart);
    setFinish(prevFinish);
    setApply(false);
  };

  /**
   * Called when the date range picker's calendar is closed.
   * @returns {void}
   */
  const closeCalendar = () => {
    setApply(true);
    myRef.setOpen(false);
  };

  /**
   * Label to use to indicate selected daterange in the HTML.
   * @param {DateTime} s start of daterange selected.
   * @param {DateTime} f finish of the daterange selected.
   * @returns {string} daterange label.
   */
  const timeSpanButtonLabel = (s, f) => {
    // If no finish ... erroneous ... return empty
    if (f === null) { return ''; }

    const appLocale = getLocale();

    // If it's for a single day, display a single day
    if (s.toSeconds() === f.toSeconds()) {
      return s.setLocale(appLocale).setZone(timezone).toFormat('DD');
    }

    // Otherwise let's make a nice label
    let startFormat = 'DD';
    if (s.month === f.month) {
      startFormat = 'd';
    } else if (s.year === f.year) {
      startFormat = 'MMM d';
    }

    const startDate = s.setLocale(appLocale).setZone(timezone).toFormat(startFormat);
    const finishDate = f.setLocale(appLocale).setZone(timezone).toFormat('DD');

    return [startDate, finishDate].flat().join(' - ');
  };

  const setTimeSpan = () => {
    if (!apply) { return; }

    const { start: prevStart, finish: prevFinish } = timespan;

    if (start === null) { setStart(prevStart.setZone(timezone)); }
    if (finish === null) { setFinish(start.setZone(timezone)); }

    // No change, nothing to see here...
    if (start.toSeconds() === prevStart.toSeconds()
      && (finish !== null && finish.toSeconds() === prevFinish.toSeconds())) {
      return;
    }

    timespanUpdateFunc(start, (finish === null ? start : finish));
  };

  /**
   * @param {Array<DateTime>} dates
   * @returns {void}
   */
  const updateStartAndFinish = (dates) => {
    const [newStart, newFinish] = dates;

    const noRanges = !start && !finish;
    const hasStartRange = start && !finish;
    const isRangeFilled = start && finish;

    if (noRanges || isRangeFilled) {
      setStart(newStart);
      setFinish(newFinish);
      return;
    }
    if (hasStartRange) {
      setStart(newStart);
      setFinish(newFinish);
    }
  };

  const renderTimeSpanPicker = (onChangeFunc, onCloseFunc) => (
    DatePickerInTimezone({
      timezone,
      start,
      finish,
      ref: setMyRef,
      timeSpanButtonLabel,
      maxNumberDays: 30,
      onChange: onChangeFunc,
      onCalendarClose: onCloseFunc,
      onCalendarOpen: openCalendar,
      customCalendarClose: closeCalendar,
    })
  );
  if (!mainData?.property || mainData?.property === undefined) {
    return (
      null
    );
  }

  if (!timespan || timespan === undefined) {
    return (null);
  }
  const intl = useIntl();

  return (
    <ButtonToolbar>

      <BtnGroupWrapper className="ms-2 mb-2">
        {renderTimeSpanPicker(updateStartAndFinish, setTimeSpan)}
      </BtnGroupWrapper>

      <Chart.ChartControls
        buttons={controlOptionFunc()}
        onButtonClick={(opts) => { controlSetStateFunc(opts); }}
      />
      <ButtonGroup>
        {chartViewSelector(viewSelectorOpen, toggle, chartView, setChartView, source)}
        {fileDownloadSelector(
          mainData,
          downloadSelectorOpen,
          toggle,
          { start, finish },
          intl,
        )}
      </ButtonGroup>

    </ButtonToolbar>
  );
}

PropertyShowControl.propTypes = {
  controlOptionFunc: PropTypes.func.isRequired,
  controlSetStateFunc: PropTypes.func.isRequired,
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_PROPERTY,
      DATA_AGGREGATE_BY_METER]).isRequired,
  }).isRequired,
  downloadSelectorOpen: PropTypes.bool.isRequired,
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setChartView: PropTypes.func.isRequired,
  source: PropTypes.oneOf([SOURCE_HISTORIAN, SOURCE_TRADES]).isRequired,
  timespan: PropTypes.shape({
    start: PropTypes.instanceOf(DateTime),
    finish: PropTypes.instanceOf(DateTime),
  }).isRequired,
  timespanUpdateFunc: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  viewSelectorOpen: PropTypes.bool.isRequired,
};

DatePickerInTimezone.propTypes = {
  customCalendarClose: PropTypes.func.isRequired,
  finish: PropTypes.instanceOf(DateTime).isRequired,
  maxNumberDays: PropTypes.number.isRequired,
  onCalendarClose: PropTypes.func.isRequired,
  onCalendarOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ref: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(DateTime).isRequired,
  timeSpanButtonLabel: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

// Ref: <https://github.com/Hacker0x01/react-datepicker/issues/862>
//
// eslint-disable-next-line react/prefer-stateless-function
class TimeSpanButton extends React.Component {
  // TODO: smatter output formatting of the range. For example:
  // - Same month: "20 - 25 Jun 2020",
  // - Same year: "20 Jun - 4 Jul 2020",
  // - Otherwise "19 Dec 2019 - 18 Jan 2020")  render() {
  render() {
    const {
      disabled,
      onClick,
      value,
    } = this.props;

    return (
      <Button className="btn btn-darken" onClick={() => (onClick())} disabled={disabled}>
        {value}
      </Button>
    );
  }
}

TimeSpanButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
TimeSpanButton.defaultProps = {
  disabled: false,
  onClick: null,
  value: 'loading...',
};

export default PropertyShowControl;

export { TimeSpanButton };
